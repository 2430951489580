/**
 * action js
 *
 * it will have all the projects related actions
 *
 * */
import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  //
  chatbotQuery: ['payload'],
  chatbotQueryStart: ['payload'],
  chatbotQuerySuccess: ['payload'],
  chatbotQueryError: ['payload'],
});
export { Types as PopupChatBoxTypes, Creators as PopupChatBoxCreators };
