/**
 * action js
 *
 * it will have all the projects related actions
 *
 * */
import { de } from 'date-fns/locale';
import { update } from 'lodash';
import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  viewProjectTableList: ['payload'],
  viewProjectTableListStart: ['payload'],
  viewProjectTableListSuccess: ['payload'],
  viewProjectTableListError: ['payload'],
  //
  projectsList: ['payload'],
  projectsListStart: ['payload'],
  projectsListSuccess: ['payload'],
  projectsListError: ['payload'],
  //
  projectFilters: ['payload'],
  resetProjectData: ['payload'],
  //
  deleteProject: ['payload'],
  deleteProjectStart: ['payload'],
  deleteProjectSuccess: ['payload'],
  deleteProjectError: ['payload'],
  // /file and folder action
  fileList: ['payload'],
  fileListStart: ['payload'],
  fileListSuccess: ['payload'],
  fileListFailure: ['payload'],
  // Create folder
  CreateFolder: ['payload'],
  CreateFolderStart: ['payload'],
  CreateFolderSuccess: ['payload'],
  CreateFolderFailure: ['payload'],
  // upload File
  uploadFile: ['payload'],
  uploadFileStart: ['payload'],
  uploadFileSuccess: ['payload'],
  uploadFileFailure: ['payload'],
  // upload folder
  uploadFolder: ['payload'],
  uploadFolderStart: ['payload'],
  uploadFolderSuccess: ['payload'],
  uploadFolderFailure: ['payload'],
  //
  updateUploadProgress: ['payload'],

  // delete folder
  deleteFolder: ['payload'],
  deleteFolderStart: ['payload'],
  deleteFolderSuccess: ['payload'],
  deleteFolderFailure: ['payload'],
  // reset Project Filter
  resetProjectFilter: ['payload'],
  // pass edit props to projectdetails component
  toggleEditMode: ['payload'],
  //
  projectDetails: ['payload'],
  projectDetailsStart: ['payload'],
  projectDetailsSuccess: ['payload'],
  projectDetailsFailure: ['payload'],
  projectDetailsReset: ['payload'],
  //
  addLink: ['payload'],
  addLinkStart: ['payload'],
  addLinkSuccess: ['payload'],
  addLinkError: ['payload'],
  //
  deleteLink: ['payload'],
  deleteLinkStart: ['payload'],
  deleteLinkSuccess: ['payload'],
  deleteLinkError: ['payload'],
  //
  editLink: ['payload'],
  editLinkStart: ['payload'],
  editLinkSuccess: ['payload'],
  editLinkError: ['payload'],
  //
  updateProject: ['payload'],
  updateProjectStart: ['payload'],
  updateProjectSuccess: ['payload'],
  updateProjectFailure: ['payload'],
  //
  getWebPageList: ['payload'],
  getWebPageListStart: ['payload'],
  getWebPageListSuccess: ['payload'],
  getWebPageListFailure: ['payload'],
  //
  addWebpages: ['payload'],
  addWebpagesStart: ['payload'],
  addWebpagesSuccess: ['payload'],
  addWebpagesFailure: ['payload'],
  //
  deleteWebPage: ['payload'],
  deleteWebPageStart: ['payload'],
  deleteWebPageSuccess: ['payload'],
  deleteWebPageFailure: ['payload'],

  //
  addSocialHandle: ['payload'],
  addSocialHandleStart: ['payload'],
  addSocialHandleSuccess: ['payload'],
  addSocialHandleFailure: ['payload'],

  listSocialHandle: ['payload'],
  listSocialHandleStart: ['payload'],
  listSocialHandleSuccess: ['payload'],
  listSocialHandleFailure: ['payload'],
  //
  updateSocialHandle: ['payload'],
  updateSocialHandleStart: ['payload'],
  updateSocialHandleSuccess: ['payload'],
  updateSocialHandleFailure: ['payload'],

  //
  deleteSocialHandle: ['payload'],
  deleteSocialHandleStart: ['payload'],
  deleteSocialHandleSuccess: ['payload'],
  deleteSocialHandleFailure: ['payload'],

  getDashboardMainMetrics: ['payload'],
  getDashboardMainMetricsStart: ['payload'],
  getDashboardMainMetricsSuccess: ['payload'],
  getDashboardMainMetricsFailure: ['payload'],
  //
  getDashboardPostMetrics: ['payload'],
  getDashboardPostMetricsStart: ['payload'],
  getDashboardPostMetricsSuccess: ['payload'],
  getDashboardPostMetricsFailure: ['payload'],
});
export { Types, Creators };
