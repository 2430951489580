import 'react-datepicker/dist/react-datepicker.css';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  CalanderDateSelect,
  CalanderFormate,
  CalendarWrape,
  CalendarWrapper,
  ChooseCalanderType,
  DateTitle,
  ScrollableDiv,
  TitleContainer,
  Wrapper,
} from './Calendar.Styles';
import { useState, useEffect, useRef, forwardRef } from 'react';
import { COLORS } from '../../constants/BaseStyles';
import Calendar from '../../assets/icons/Calendar.svg';
import {
  COLOR_POST_MAPPING,
  PROJECT_STATUS,
  INTERNAL,
  AGENCY,
} from '../../constants/constant';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PostsCreators } from './store/action';
import { CHECK_DUE_DATE_CROSSED } from '../../constants/functions';
import { loggedUserCreators } from '../homepage/store/action';
import { Col, Row } from '../../components/CalendarModal/CalendarModal.Styles';
import { NameWrapper } from '../BaseLayout/BaseLayout.Styles';
import { Title } from '../assetVersions/AssetVersion.Style';
import UserCard from '../../components/Cards/UserCard.View';
import UniversalModal from '../../components/modals/UniversalModal';
import AddPostform from '../../components/form/AddPostForm.View';
import { STRINGS } from '../../constants/labels';
import SubHeader from '../../components/SubHeader/SubHeader.View';
import DynamicSVGIcon from '../../components/DynamicSVGIcon';
//
import DropDownIcon from '../../assets/icons/DropDownIcon.svg';
const CalendarView = () => {
  //TEXT
  const strings = STRINGS['ENGLISH'];
  const [searchParams] = useSearchParams();
  const projectName = searchParams.get('project_name');
  //store
  const { postsListData } = useSelector((state) => state.getPosts);
  const { userData } = useSelector((state) => state.loggedinUser);

  const { projectDetails } = useSelector((state) => state.project);

  //redux
  const dispatch = useDispatch();

  //nav
  const navigate = useNavigate();

  //states
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dateSelected, setDateSelected] = useState();
  const [isViewMorePopupVisible, setViewMorePopupVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const param = useParams();
  //
  const [selectedDate, setSelectedDate] = useState(new Date());
  const calendarRef = useRef(null);
  const datePickerRef = useRef(null);
  //
  const { project_id, project_path } = param;

  //display all events
  useEffect(() => {
    // deciding the path for previous page
    dispatch(loggedUserCreators.backAction({ goBackPath: '/projects' }));
    dispatch(PostsCreators.getPosts({ project_id }));
  }, []);

  //color mapping
  const modifiedPostsListData = postsListData.map((event) => ({
    ...event,
    color: COLOR_POST_MAPPING[event.status],
  }));

  //add new event
  const handleSelect = (info) => {
    setDateSelected(info.start); //set date to what user clicks on calendar
    setIsOpenModal(true);
  };

  //hide plus icon when view more events are clicked
  useEffect(() => {
    const handleDocumentClick = (event) => {
      setViewMorePopupVisible(true);
      if (event.target.closest('.fc-more-link')) {
        setViewMorePopupVisible(true);
      } else {
        setViewMorePopupVisible(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isViewMorePopupVisible]);

  //save new event
  const saveEvent = () => {
    const start = dateSelected;
    const addPostData = {
      title,
      start,
      desc,
      allDay: true,
      status: PROJECT_STATUS[0].value,
      project_id: project_id, //REMOVE: FOR TESTING ONLY
    };
    dispatch(PostsCreators.addPost({ addPostData, project_id }));
    closeModal();
  };

  //view event
  const handleEventClick = (info) => {
    const event_id = info.event.id;
    navigate(`/${project_path}/${project_id}/calendar/events/${event_id}`);
  };

  // Function to check if a date is in the past
  const isPastDate = (date) => {
    return CHECK_DUE_DATE_CROSSED(date);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setTitle('');
    setDesc('');
  };

  // Get today's date in UTC format
  const today = new Date().toISOString().split('T')[0];
  // Filter events where started_at is today
  const updatedToday = postsListData?.filter(
    (event) => event.start && moment(event.start).isSame(today)
  );

  // Extract title and updated_by from filtered events
  const updatedInfoToday = updatedToday?.map((event) => ({
    ...event,
    title: event.title,
    updated_by: event.updated_by,
  }));

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(date);
    }
  };

  const handleInputClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(false);
    }
  };

  return (
    <>
      <SubHeader
        buttonDisplay={'none'}
        title={projectDetails[project_id]?.project_name || projectName}
        customBackFunction={() => {
          const url = `/brands/${projectDetails[project_id]?.base_company_id}?company_name=${projectDetails[project_id]?.base_company_name}`;
          navigate(url);
        }}
      />

      <CalendarWrape>
        <Wrapper>
          <CalendarWrapper
            category={userData?.type}
            hidePlus={isViewMorePopupVisible}
          >
            {/* <ChooseCalanderType>
              <DynamicSVGIcon iconUrl={Calendar} />
              <CalanderFormate>Today</CalanderFormate>
              <DynamicSVGIcon iconUrl={DropDownIcon} rotate={90} />
            </ChooseCalanderType> */}
            <CalanderDateSelect>
              <ReactDatePicker
                style={{ width: '235px' }}
                ref={datePickerRef}
                selected={selectedDate}
                onChange={handleDateChange}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                customInput={
                  <TitleContainer onClick={handleInputClick}>
                    <DateTitle>
                      {moment(selectedDate).format('MMMM YYYY')}
                    </DateTitle>
                    <DynamicSVGIcon
                      iconUrl={DropDownIcon}
                      rotate={-90}
                      width="1rem"
                    />
                  </TitleContainer>
                }
              />
            </CalanderDateSelect>

            <FullCalendar
              ref={calendarRef}
              selectable
              select={handleSelect}
              eventClick={handleEventClick}
              events={modifiedPostsListData}
              eventLimit={'true'}
              // dayMaxEvents={2}
              eventColor={COLORS.GREEN}
              contentHeight={window?.innerHeight - 300}
              buttonText={{
                today: 'Today',
                month: 'Monthly',
                week: 'Weekly',
                day: 'Daily',
                list: 'Yearly',
              }}
              headerToolbar={{
                // left: 'prev,next today',
                // center: 'left',
                // <----------paste these list of iteams in right prop to enable multiple view of calnder--------------->
                // dayGridMonth,timeGridWeek,timeGridDay,listYear
                right: '',
              }}
              initialView="dayGridMonth"
              selectAllow={(selectInfo) =>
                !isPastDate(projectDetails[project_id]?.started_at)
              }
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                listPlugin,
                interactionPlugin,
              ]}
              dayHeaderContent={(args) => {
                // Format the day names to uppercase
                return args.date
                  .toLocaleDateString('en-US', { weekday: 'long' })
                  .toUpperCase();
              }}
            />
          </CalendarWrapper>
        </Wrapper>

        <Row width={'30%'} marginRight={'20px'} flexStart>
          <ScrollableDiv>
            <Col width={'300px'} justify={'flex-start'} align={'flex-start'}>
              <Title fontSize={'18px'}>{strings.TODAYS_POST}</Title>
              {updatedInfoToday?.map((data, index) => (
                <Link
                  key={index}
                  to={`/${project_path}/${project_id}/calendar/events/${data.id}`}
                >
                  <Col
                    width={'300px'}
                    height={'100px'}
                    marginTop={'10px'}
                    justify={'space-between'}
                    borderBottom
                  >
                    <Row flexStart marginTop={'10px'}>
                      <NameWrapper fontSize={'13px'}>{data?.title}</NameWrapper>
                    </Row>
                    <UserCard
                      profilePic={data?.updated_by?.profile_pic}
                      username={data?.updated_by?.name}
                      usernameFontSize={'12px'}
                      rightDiv={data?.time}
                      marginBottom={'8px'}
                      usernameColor={COLORS.PURPLE_ALT}
                    />
                  </Col>
                </Link>
              ))}
              {updatedInfoToday.length === 0 && (
                <Col
                  width={'300px'}
                  justify={'flex-start'}
                  align={'flex-start'}
                  marginTop={'10px'}
                >
                  <Title fontSize={'14px'}>{strings.NO_POST_TODAY}</Title>
                </Col>
              )}
            </Col>
          </ScrollableDiv>
        </Row>
      </CalendarWrape>
      {(userData?.type?.toLowerCase() === INTERNAL ||
        userData?.type?.toLowerCase() === AGENCY) && (
        <UniversalModal
          isOpenModal={isOpenModal}
          btn={true}
          leftText={strings.CANCEL}
          rightText={strings.ADD}
          height={'400px'}
          width={'600px'}
          // rightBtnDisabled={isScraping}
          onrightbtnClick={saveEvent}
          onleftbtnClick={closeModal}
        >
          <AddPostform
            title={title}
            setTitle={setTitle}
            desc={desc}
            setDesc={setDesc}
          />
        </UniversalModal>
      )}
    </>
  );
};

export default CalendarView;
