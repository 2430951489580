import { useState, useEffect } from 'react';
import {
  CartesianGrid,
  Legend,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
} from 'recharts';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store/action';
import { capitalizeFirstLetter } from '../../constants/functions';
import {
  Col,
  Label,
  Row,
} from '../../components/CalendarModal/CalendarModal.Styles';

import { P } from './Project.Styles';
import { Title } from '../assetVersions/AssetVersion.Style';
import UserCard from '../../components/Cards/UserCard.View';
import { STRINGS } from '../../constants/labels';

import BlueKabab from '../../assets/icons/BlueKabab.svg';
import DynamicSVGIcon from '../../components/DynamicSVGIcon';
import {
  PROJECT_DASHBOARD_MAJOR_REPORTS_TAB,
  PROJECT_DETAILS_MAPPING,
  STATUS,
} from '../../constants/constant';
import SubHeader from '../../components/SubHeader/SubHeader.View';
import { Website } from './ProjectDetailBaseView.Styles';
import { Tooltip } from 'react-tooltip';
import { ScrollableDiv } from '../calendar/Calendar.Styles';
import { COLORS } from '../../constants/BaseStyles';
import InnerLoader from '../../components/loader/InnerLoader.View';
import Empty from '../../components/EmptyFileComponent/EmptyFile.View';

const ProjectDashboardView = () => {
  //TEXT
  const strings = STRINGS['ENGLISH'];
  const [searchParams] = useSearchParams();
  const { userData } = useSelector((state) => state.loggedinUser);
  const projectName = searchParams.get('project_name');

  const [activeTab, setActiveTab] = useState(
    PROJECT_DASHBOARD_MAJOR_REPORTS_TAB[0].value
  );

  const {
    projectsFileHeader,
    projectDetails,
    mainMetricsData,
    postMetricsData,
    postMetricsLoading,
    mainMetricsLoading,
  } = useSelector((state) => state.project);
  //navigation
  const navigate = useNavigate();

  //redux
  const dispatch = useDispatch();

  //use params
  const { project_id, project_path } = useParams();

  useEffect(() => {
    dispatch(Creators.getDashboardMainMetrics({ projectId: project_id }));
    dispatch(Creators.getDashboardPostMetrics({ projectId: project_id }));
  }, [project_id]);

  return (
    <>
      <SubHeader
        buttonDisplay={'none'}
        title={projectDetails[project_id]?.project_name || projectName}
        customBackFunction={() => {
          const url = `/brands/${projectDetails[project_id]?.base_company_id}`;
          navigate(url);
        }}
      />
      <div style={{ marginTop: '70px' }}>
        <ScrollableDiv>
          <Row
            padding={'10px'}
            marginLeft={'100px'}
            spaceBetween
            alignItems={'flex-start'}
          >
            <Col
              width={'100%'}
              align={'flex-start'}
              justify={'flex-start'}
              height={'100vh'}
            >
              <Row
                flexStart
                marginBottom={'40px'}
                alignItems={'flex-start'}
                marginTop={'5px'}
                changeDirection
                width={'97%'}
                gap={'10px'}
              >
                <Col
                  width={'48%'}
                  align={'flex-start'}
                  height={'400px'}
                  justify={'flex-start'}
                  lightBorder
                  padding={'10px'}
                  bgColor
                >
                  <Title textCenter width={'100%'}>
                    {strings.REACH}
                  </Title>
                  {postMetricsLoading ? (
                    <InnerLoader />
                  ) : (
                    <>
                      {mainMetricsData?.reach?.length === 0 ? (
                        <Empty
                          heading={strings.NOT_RECORD_FOUND}
                          height={'15vh'}
                        />
                      ) : (
                        <ResponsiveContainer width={'100%'} height={'80%'}>
                          <LineChart
                            data={mainMetricsData?.reach}
                            width={500}
                            height={300}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="1 8" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip
                              cursor={{ stroke: 'red', strokeWidth: 2 }}
                            />
                            <Legend />
                            {mainMetricsData?.keys?.map((key, index) => (
                              <Line
                                type="monotone"
                                dataKey={key}
                                stroke={COLORS.RANDOM_COLORS[index]}
                                key={key}
                                dot={false}
                              />
                            ))}
                          </LineChart>
                        </ResponsiveContainer>
                      )}
                    </>
                  )}
                </Col>
                <Col
                  width={'48%'}
                  align={'flex-start'}
                  height={'400px'}
                  justify={'flex-start'}
                  lightBorder
                  padding={'10px'}
                  bgColor
                >
                  <Title textCenter width={'100%'}>
                    {strings.FOLLOWS}
                  </Title>
                  {postMetricsLoading ? (
                    <InnerLoader />
                  ) : (
                    <>
                      {mainMetricsData?.follows?.length === 0 ? (
                        <Empty
                          heading={strings.NOT_RECORD_FOUND}
                          height={'15vh'}
                        />
                      ) : (
                        <ResponsiveContainer width={'100%'} height={'80%'}>
                          <LineChart
                            data={mainMetricsData?.follows}
                            width={500}
                            height={300}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="1 8" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {mainMetricsData?.keys?.map((key, index) => {
                              return (
                                <Line
                                  type="monotone"
                                  dataKey={key}
                                  stroke={COLORS.RANDOM_COLORS[index]}
                                  key={key}
                                  dot={false}
                                />
                              );
                            })}
                          </LineChart>
                        </ResponsiveContainer>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <Row
                flexStart
                marginBottom={'40px'}
                alignItems={'flex-start'}
                marginTop={'5px'}
                changeDirection
                width={'97%'}
                gap={'10px'}
              >
                <Col
                  width={'48%'}
                  align={'flex-start'}
                  height={'400px'}
                  justify={'flex-start'}
                  lightBorder
                  padding={'10px'}
                  bgColor
                >
                  <Title textCenter width={'100%'}>
                    {strings.INTERACTIONS}
                  </Title>
                  {postMetricsLoading ? (
                    <InnerLoader />
                  ) : (
                    <>
                      {mainMetricsData?.interactions?.length === 0 ? (
                        <Empty
                          heading={strings.NOT_RECORD_FOUND}
                          height={'15vh'}
                        />
                      ) : (
                        <ResponsiveContainer width={'100%'} height={'80%'}>
                          <LineChart
                            data={mainMetricsData?.interactions}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="1 8" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {mainMetricsData?.keys?.map((key, index) => {
                              return (
                                <Line
                                  type="monotone"
                                  dataKey={key}
                                  stroke={COLORS.RANDOM_COLORS[index]}
                                  key={key}
                                  dot={false}
                                />
                              );
                            })}
                          </LineChart>
                        </ResponsiveContainer>
                      )}
                    </>
                  )}
                </Col>
                <Col
                  width={'48%'}
                  align={'flex-start'}
                  height={'400px'}
                  justify={'flex-start'}
                  lightBorder
                  padding={'10px'}
                  bgColor
                >
                  <Title textCenter width={'100%'}>
                    {strings.LINK_CLICKS}
                  </Title>
                  {postMetricsLoading ? (
                    <InnerLoader />
                  ) : (
                    <ResponsiveContainer width={'100%'} height={'80%'}>
                      <LineChart
                        data={mainMetricsData?.link_clicks}
                        // width={500}
                        // height={300}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="1 8" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {mainMetricsData?.keys?.map((key, index) => {
                          return (
                            <Line
                              type="monotone"
                              dataKey={key}
                              stroke={COLORS.RANDOM_COLORS[index]}
                              key={key}
                              dot={false}
                            />
                          );
                        })}
                      </LineChart>
                    </ResponsiveContainer>
                  )}
                </Col>
              </Row>
              <Row
                flexStart
                marginBottom={'40px'}
                alignItems={'flex-start'}
                marginTop={'5px'}
                changeDirection
                width={'97%'}
                gap={'10px'}
              >
                <Col
                  width={'48%'}
                  align={'flex-start'}
                  height={'400px'}
                  justify={'flex-start'}
                  lightBorder
                  padding={'10px'}
                  bgColor
                >
                  <Title textCenter width={'100%'}>
                    {strings.VIEWS}
                  </Title>
                  {postMetricsLoading ? (
                    <InnerLoader />
                  ) : (
                    <>
                      {mainMetricsData?.views?.length === 0 ? (
                        <Empty
                          heading={strings.NOT_RECORD_FOUND}
                          height={'15vh'}
                        />
                      ) : (
                        <ResponsiveContainer width={'100%'} height={'80%'}>
                          <LineChart
                            data={mainMetricsData?.views}
                            // width={500}
                            // height={300}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="1 8" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {mainMetricsData?.keys?.map((key, index) => {
                              return (
                                <Line
                                  type="monotone"
                                  dataKey={key}
                                  stroke={COLORS.RANDOM_COLORS[index]}
                                  key={key}
                                  dot={false}
                                />
                              );
                            })}
                          </LineChart>
                        </ResponsiveContainer>
                      )}
                    </>
                  )}
                </Col>
                <Col
                  width={'48%'}
                  align={'flex-start'}
                  height={'400px'}
                  justify={'flex-start'}
                  lightBorder
                  padding={'10px'}
                  bgColor
                >
                  <Title textCenter width={'100%'}>
                    {strings.VISITS}
                  </Title>
                  {postMetricsLoading ? (
                    <InnerLoader />
                  ) : (
                    <>
                      {mainMetricsData?.visits?.length === 0 ? (
                        <Empty
                          heading={strings.NOT_RECORD_FOUND}
                          height={'15vh'}
                        />
                      ) : (
                        <ResponsiveContainer width={'100%'} height={'80%'}>
                          <LineChart
                            data={mainMetricsData?.visits}
                            // width={300}
                            // height={300}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="1 8" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {mainMetricsData?.keys?.map((key, index) => {
                              return (
                                <Line
                                  type="monotone"
                                  dataKey={key}
                                  stroke={COLORS.RANDOM_COLORS[index]}
                                  key={key}
                                  dot={false}
                                />
                              );
                            })}
                          </LineChart>
                        </ResponsiveContainer>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <Row
                flexStart
                marginBottom={'10px'}
                alignItems={'flex-start'}
                marginTop={'5px'}
                changeDirection
                width={'97%'}
                gap={'10px'}
              >
                <Title>{strings.TOP_POSTS}</Title>
              </Row>

              <Row
                flexStart
                marginBottom={'40px'}
                alignItems={'flex-start'}
                marginTop={'5px'}
                changeDirection
                width={'90%'}
                gap={'10px'}
                flexWrap
              >
                {mainMetricsLoading ? (
                  <InnerLoader />
                ) : (
                  <>
                    {postMetricsData?.length === 0 ? (
                      <Empty
                        heading={strings.NOT_RECORD_FOUND}
                        height={'15vh'}
                      />
                    ) : (
                      postMetricsData?.map((post, index) => {
                        return (
                          <Link to={post?.post_permalink} target="_blank">
                            <Col
                              key={index}
                              width={'400px'}
                              lightBorder
                              padding={'20px'}
                              bgColor
                            >
                              <Title width={'100%'}>{post?.post_title}</Title>
                              <Row flexStart>
                                <Label fontSize={'13px'} noMarginTop>
                                  <Row spaceBetween width={'90%'}>
                                    {strings.VIEWS}
                                    <DynamicSVGIcon
                                      iconUrl={BlueKabab}
                                      width="0.13rem"
                                    />
                                  </Row>
                                </Label>
                                <P>{post?.post_views}</P>
                              </Row>
                              <Row flexStart>
                                <Label fontSize={'13px'} noMarginTop>
                                  <Row spaceBetween width={'90%'}>
                                    {strings.REACH}
                                    <DynamicSVGIcon
                                      iconUrl={BlueKabab}
                                      width="0.13rem"
                                    />
                                  </Row>
                                </Label>
                                <P>{post?.post_reach}</P>
                              </Row>
                              <Row flexStart>
                                <Label fontSize={'13px'} noMarginTop>
                                  <Row spaceBetween width={'90%'}>
                                    {strings.REACTIONS}
                                    <DynamicSVGIcon
                                      iconUrl={BlueKabab}
                                      width="0.13rem"
                                    />
                                  </Row>
                                </Label>
                                <P>{post?.post_reactions}</P>
                              </Row>
                            </Col>
                          </Link>
                        );
                      })
                    )}
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </ScrollableDiv>
      </div>
    </>
  );
};

export default ProjectDashboardView;
