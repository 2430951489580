/**
 * reducer js
 *
 * it will have all the project related reducers
 */

import { createReducer } from 'reduxsauce';
import { PopupChatBoxTypes } from './action';

const INITIAL_STATE = {
  error: false,
  popChatBoxLoading: false,
  popChatBoxData: [],
  popChatBoxHeader: {},
  popChatBoxError: false,
  popChatBoxErrorMessage: '',
};

const chatbotQueryStart = (state, action) => {
  const { data } = action.payload;
  const newData = [...state.popChatBoxData, ...data];
  return { ...state, popChatBoxLoading: true, popChatBoxData: newData };
};

const chatbotQuerySuccess = (state, action) => {
  const { data } = action.payload;
  const newData = [...state.popChatBoxData, ...data];
  return {
    ...state,
    popChatBoxLoading: false,
    popChatBoxData: newData,
  };
};

const chatbotQueryError = (state, action) => {
  const { data } = action.payload;
  const newData = [...state.popChatBoxData, ...data];
  return {
    ...state,
    popChatBoxLoading: false,
    popChatBoxData: newData,
    popChatBoxErrorMessage: 'Error in getting the data try again later',
  };
};

const HANDLERS = {
  [PopupChatBoxTypes.CHATBOT_QUERY_START]: chatbotQueryStart,
  [PopupChatBoxTypes.CHATBOT_QUERY_SUCCESS]: chatbotQuerySuccess,
  [PopupChatBoxTypes.CHATBOT_QUERY_ERROR]: chatbotQueryError,
  //
};

export const popupChatBoxReducer = createReducer(INITIAL_STATE, HANDLERS);
