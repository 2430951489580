/**
 * actions js
 *
 * it will have all the actions will be combined
 *
 * For more function follow axios documentation
 *
 * Project: MakroApp
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import {
  Types as AuthTypes,
  Creators as AuthCreators,
} from '../pages/auth/store/action';

import {
  Types as ProjectsTypes,
  Creators as ProjectsCreators,
} from '../pages/projects/store/action';
import {
  AddProjectsTypes,
  AddProjectsCreators,
} from '../pages/addProjects/store/action';
import { AssetType, AssetCreators } from '../pages/assetsView/store/action';
import { UserCreators, UserTypes } from '../pages/users/store/action';

import { AddUserTypes, AddUserCreators } from '../pages/addUser/store/action';
// import { PostsTypes, PostsCreators } from '../pages/calendar/store/action';
import { PostsTypes, PostsCreators } from '../pages/calendar/store/action';
import { EventTypes, EventCreators } from '../pages/viewPosts/store/action';
import { ClientTypes, ClientCreators } from '../pages/clients/store/action';
import {
  AssetsTypes,
  AssetsCreators,
} from '../pages/uploadAssets/store/action';

import {
  AssetVersionsTypes,
  AssetVersionsCreators,
} from '../pages/assetVersions/store/action';

import {
  loggedUserTypes,
  loggedUserCreators,
} from '../pages/homepage/store/action';

import {
  MyAgencyTypes,
  MyAgencyCreators,
} from '../pages/myAgency/store/action';

import {
  WebpageTypes,
  WebpageCreators,
} from '../pages/WebsiteFeedback/store/action';
import { BrandTypes, BrandCreators } from '../pages/brands/store/action';
import { BaseTypes, BaseCreators } from '../pages/BaseLayout/store/action';
import { PopupChatBoxTypes } from '../pages/PopupChatBox/store/action';

const Types = {
  ...AuthTypes,
  ...UserTypes,
  ...AddUserTypes,
  ...ProjectsTypes,
  ...AddProjectsTypes,
  ...PostsTypes,
  ...EventTypes,
  ...AssetsTypes,
  ...AssetVersionsTypes,
  ...ClientTypes,
  ...loggedUserTypes,
  ...MyAgencyTypes,
  ...WebpageTypes,
  ...BrandTypes,
  ...BaseTypes,
  ...AssetType,
  ...PopupChatBoxTypes,
};

const Creators = {
  ...AuthCreators,
  ...UserCreators,
  ...AddUserCreators,
  ...ProjectsCreators,
  ...AddProjectsCreators,
  ...PostsCreators,
  ...EventCreators,
  ...AssetsCreators,
  ...AssetVersionsCreators,
  ...ClientCreators,
  ...loggedUserCreators,
  ...MyAgencyCreators,
  ...WebpageCreators,
  ...BrandCreators,
  ...BaseCreators,
  ...AssetCreators,
};

export { Types, Creators };
