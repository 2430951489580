/**
 * reducers js
 *
 * it will have all the reducers will be combined
 *
 * For more function follow axios documentation
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { projectsReducer } from '../pages/projects/store/reducer';
import { addProjectReducer } from '../pages/addProjects/store/reducer';
import { combineReducers } from 'redux';
import { authReducer } from '../pages/auth/store/reducer';
import { userListReducer } from '../pages/users/store/reducer';
import { addUserReducer } from '../pages/addUser/store/reducer';
import { PostsReducer } from '../pages/calendar/store/reducer';
import { EventReducers } from '../pages/viewPosts/store/reducer';
import { AssetsReducer } from '../pages/uploadAssets/store/reducer';
import { AssetVersionsReducer } from '../pages/assetVersions/store/reducer';
import { clientsReducer } from '../pages/clients/store/reducer';
import { loggedUserReducer } from '../pages/homepage/store/reducer';
import { myAgencyReducer } from '../pages/myAgency/store/reducer';
import { WebpageReducer } from '../pages/WebsiteFeedback/store/reducer';
import { brandReducer } from '../pages/brands/store/reducer';
import { baseReducer } from '../pages/BaseLayout/store/reducer';
import { assetsReducer } from '../pages/assetsView/store/reducer';
import { popupChatBoxReducer } from '../pages/PopupChatBox/store/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  project: projectsReducer,
  addProject: addProjectReducer,
  userList: userListReducer,
  addUser: addUserReducer,
  getPosts: PostsReducer,
  getEvent: EventReducers,
  addAsset: AssetsReducer,
  getAssetVersions: AssetVersionsReducer,
  clients: clientsReducer,
  loggedinUser: loggedUserReducer,
  myAgency: myAgencyReducer,
  webpageFeedback: WebpageReducer,
  brand: brandReducer,
  base: baseReducer,
  asset: assetsReducer,
  popupChatBox: popupChatBoxReducer,
});

export default rootReducer;
