/**
 * saga js
 *
 * it will have all the project related sagas
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { PopupChatBoxCreators } from './action';

export function* chatbotQuerySaga(action) {
  try {
    const {
      payload: { text },
    } = action;
    const fromattedResponse = [
      {
        summary: text,
        userType: 'USER',
      },
    ];
    yield put(
      PopupChatBoxCreators.chatbotQueryStart({ data: fromattedResponse })
    );
    // yield delay(2000);

    const response = yield call(ApiService.post, apiEndPoints.chatbot_query, {
      query: text,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      // console.log('data', data.data);
      const fromattedResponse = [
        {
          summary: data.data.summary,
          userType: 'CHATBOT',
        },
      ];
      yield put(
        PopupChatBoxCreators.chatbotQuerySuccess({ data: fromattedResponse })
      );
    } else {
      throw response;
    }
  } catch (error) {
    const fromattedResponse = [
      {
        summary: 'Unable to precess the request',
        userType: 'CHATBOT',
      },
    ];
    yield put(
      PopupChatBoxCreators.chatbotQueryError({
        fromattedResponse,
      })
    );
  }
}
