/**
 * reducer js
 *
 * it will have all the project related reducers
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  error: false,
  loading: true,
  projectsListLoading: true,
  deleteLoading: false,
  listFileStatus: false,
  selectLoading: false,
  uploadFolderloading: false,
  uploadFileloading: false,
  deleteFolderloading: false,
  projectTableListData: [],
  projectsData: [],
  projectListHeader: {},
  filter: {},
  searchValue: '',
  projectSelectData: [],
  selectedfilters: {},
  projectsFileListData: [],
  isEditMode: false,
  projectDetails: {},
  projectsLoading: false,
  addLinkLoading: false,
  uploadFiles: [],
  deleteLinkLoading: false,
  editLinkLoading: false,
  uploadProgress: 0,
  files: {},
  getWebpagesLoading: false,
  addWebsiteUrlLoading: false,
  webPageUrlList: {},
  webPageUrlHeader: {},
  deleteWebPageLoading: false,
  addSocialHandleLoading: false,
  listSocialHandleLoading: false,
  listSocialHandleData: {},
  listSocialHandleHeader: {},
  mainMetricsLoading: false,
  mainMetricsData: {},
  postMetricsLoading: false,
  postMetricsData: [],
};
// <-----------------------reducer for viewing list of projects-------------------->
const viewProjectTableListStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    projectsListLoading: true,
  };
};
const viewProjectTableListSuccess = (state = INITIAL_STATE, action) => {
  const { data, projectId } = action.payload;
  const header = {
    columns: data?.columns,
    count: data?.count,
    pageNumber: data?.page_number,
    pagesize: data?.page_size,
    lang: 'en',
  };
  let projectTableListData = state.projectTableListData;
  if (data?.page_number === 1) {
    projectTableListData[projectId] = data?.data;
  } else {
    projectTableListData[projectId] = [
      ...state.projectTableListData[projectId],
      ...data?.data,
    ];
  }
  return {
    ...state,
    error: false,
    projectsListLoading: false,
    projectListHeader: {
      ...state.projectListHeader,
      [projectId]: header,
    },
    projectTableListData: projectTableListData,
  };
};
const viewProjectTableListError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    error: true,
    projectsListLoading: false,
    errorMessage,
  };
};
//
// <-----------------------reducer for viewing list of projects-------------------->
const projectsListsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    projectsLoading: true,
  };
};
const projectsListsSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  let projectList = [];
  if (data?.page_number === 1) {
    projectList = data?.data;
  } else {
    projectList = [...state.projectsData, ...data?.data];
  }
  return {
    ...state,
    error: false,
    projectsLoading: false,
    // projectListHeader: header,
    projectsData: projectList,
  };
};
const projectsListsError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    error: true,
    projectsLoading: false,
    errorMessage,
  };
};
const projectFiltersReducer = (state = INITIAL_STATE, action) => {
  const { filter, searchValue, selectedOption } = action.payload;
  return {
    ...state,
    filter: filter,
    searchValue: searchValue,
    selectedfilters: selectedOption,
  };
};
// <-----------------------reducer for reset the projects list-------------------->
const resetProjectData = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    // projectsListData: [],
  };
};
// <---------------reducer for delete project--------------------->
const deleteProjectStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteLoading: true,
  };
};
const deleteProjectSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteLoading: false,
  };
};
const deleteProjectError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteLoading: false,
  };
};
// <---------------reducer for file and folder managemant in project--------------------->
const fileListStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listFileStatus: true,
  };
};
const fileListSuccess = (state = INITIAL_STATE, action) => {
  const { data, projectId } = action.payload;
  const header = {
    columns: data?.columns,
    count: data?.count,
    pageNumber: data?.page_number,
    pagesize: data?.page_size,
    lang: 'en',
    curr_dir_path: data?.curr_dir_path,
  };
  //

  let projectTableListData = state.projectTableListData;
  if (data?.page_number === 1) {
    projectTableListData[projectId] = data?.data;
  } else {
    projectTableListData[projectId] = [
      ...state.projectTableListData[projectId],
      ...data?.data,
    ];
  }
  //
  let projectsFileListData = state?.projectsFileListData;
  if (data?.page_number === 1) {
    projectsFileListData[projectId] = data?.data;
  } else {
    projectsFileListData[projectId] = [
      ...state.projectsFileListData[projectId],
      ...data?.data,
    ];
  }
  return {
    ...state,
    listFileStatus: false,
    projectsFileHeader: header,
    projectsFileListData: projectsFileListData,
  };
};
const fileListFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listFileStatus: false,
  };
};
// <---------------reducer for create folder in project--------------------->
const createFolderStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    createFolderloading: true,
  };
};
const CreateFolderSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    createFolderloading: false,
  };
};
const CreateFolderFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    createFolderloading: false,
  };
};

// <---------------reducer for upload file--------------------->
const uploadFileStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadFileloading: true,
  };
};
const uploadFileSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadFileloading: false,
  };
};
const uploadFileFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadFileloading: false,
  };
};
//
// <---------------reducer for upload folder--------------------->
const uploadFolderStart = (state = INITIAL_STATE, action) => {
  const { files } = action.payload;
  const fileNames = files.map((data) => {
    return data?.name;
  });
  return {
    ...state,
    uploadFolderloading: true,
    uploadFiles: fileNames,
  };
};
const uploadFolderSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadFolderloading: false,
    uploadFiles: [],
    files: {},
  };
};
const uploadFolderFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadFolderloading: false,
    uploadFiles: [],
  };
};
//
const updateUploadProgressReducer = (state = INITIAL_STATE, action) => {
  const { percentCompleted, fileName } = action.payload;
  const updatedFiles = {
    ...state.files,
    [fileName]: {
      ...state.files[fileName],
      percentCompleted,
    },
  };
  return {
    ...state,
    files: updatedFiles,
    // uploadFileloading: true,
  };
};
// <---------------reducer for delete folder--------------------->
const deleteFolderStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteFolderloading: true,
  };
};
const deleteFolderSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteFolderloading: false,
  };
};
const deleteFolderFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteFolderloading: false,
  };
};
const resetProjectFilter = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    filter: {},
    searchValue: '',
    selectedfilters: {},
  };
};

const toggleEditMode = (state) => ({
  ...state,
  isEditMode: !state.isEditMode,
});
// <---------------reducer for project details----------------->
const projectDetailsStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const projectDetailsSuccessReducer = (state = INITIAL_STATE, action) => {
  const { data, projectId } = action.payload;
  return {
    ...state,
    projectDetails: {
      ...state.projectDetails,
      [projectId]: data,
    },
  };
};
const projectDetailsErrorReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
// <---------------reducer for add website url--------------------->
const addLinkStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addLinkLoading: true,
  };
};
const addLinkSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addLinkLoading: false,
  };
};
const addLinkError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addLinkLoading: false,
  };
};
// <---------------reducer for delete website url--------------------->
const deleteLinkStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteLinkLoading: true,
  };
};
const deleteLinkSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteLinkLoading: false,
  };
};
const deleteLinkError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteLinkLoading: false,
  };
};
// <---------------reducer for delete website url--------------------->
const editLinkStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    editLinkLoading: true,
  };
};
const editLinkSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    editLinkLoading: false,
  };
};
const editLinkError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    editLinkLoading: false,
  };
};
// <---------------reducer for update project--------------------->
const updateProjectStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    updateProjectloading: true,
  };
};
const updateProjectSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    updateProjectloading: false,
  };
};
const updateProjectFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    updateProjectloading: false,
  };
};
// <---------------  reducer for get websites from list----------------->
const getWebPageListStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    getWebPageListLoading: true,
  };
};

const getWebPageListSuccess = (state = INITIAL_STATE, action) => {
  const { data, projectId } = action.payload;
  const header = {
    columns: data?.columns,
    count: data?.count,
    pageNumber: data?.page_number,
    pagesize: data?.page_size,
    lang: 'en',
  };
  let websiteUrlList = state.webPageUrlList;
  if (data?.page_number === 1) {
    websiteUrlList[projectId] = data?.data;
  } else {
    websiteUrlList[projectId] = [
      ...state.webPageUrlList[projectId],
      ...data?.data,
    ];
  }
  // data
  return {
    ...state,
    getWebPageListLoading: false,
    webPageUrlList: websiteUrlList,
    webPageUrlHeader: {
      ...state.webPageUrlHeader,
      [projectId]: header,
    },
  };
};

const getWebPageListFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    getWebpagesLoading: false,
  };
};
// <---------------  reducer add websites for scraping ----------------->
const addWebsiteUrlStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addWebsiteUrlLoading: true,
  };
};

const addWebsiteUrlSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addWebsiteUrlLoading: false,
  };
};

const addWebsiteUrlFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addWebsiteUrlLoading: false,
  };
};
//
// <---------------reducer for delete website from list--------------------->
const deleteWebPageStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteWebPageLoading: true,
  };
};
const deleteWebPageSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteWebPageLoading: false,
  };
};
const deleteWebPageError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteWebPageLoading: false,
  };
};

//
// <---------------reducer for delete website from list--------------------->
const addSocialHandleStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addSocialHandleLoading: true,
  };
};
const addSocialHandleSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addSocialHandleLoading: false,
  };
};
const addSocialHandleError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addSocialHandleLoading: false,
  };
};

const listSocialHandleStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listSocialHandleLoading: true,
  };
};
const listSocialHandleSuccess = (state = INITIAL_STATE, action) => {
  const { data, projectId } = action.payload;
  const header = {
    columns: data?.meta?.columns,
    count: data?.meta?.count,
    pageNumber: data?.meta?.page_number,
    pagesize: data?.meta?.page_size,
    lang: 'en',
  };
  let listSocialHandleData = state.listSocialHandleData;
  if (data?.meta?.page_number === 1) {
    listSocialHandleData[projectId] = data?.data;
  } else {
    listSocialHandleData[projectId] = [
      ...state.listSocialHandleData[projectId],
      ...data?.data,
    ];
  }
  return {
    ...state,
    error: false,
    listSocialHandleLoading: false,
    listSocialHandleHeader: {
      ...state.listSocialHandleHeader,
      [projectId]: header,
    },
    listSocialHandleData: listSocialHandleData,
  };
};
const listSocialHandleError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    error: true,
    listSocialHandleLoading: false,
    errorMessage,
  };
};

const getDashboardMainMetricsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    mainMetricsLoading: true,
  };
};
const getDashboardMainMetricsSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    mainMetricsLoading: false,
    mainMetricsData: data,
  };
};
const getDashboardMainMetricsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    mainMetricsLoading: false,
    error: true,
    errorMessage: 'Error in loading data',
  };
};

const getDashboardPostMetricsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    postMetricsLoading: true,
  };
};
const getDashboardPostMetricsSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    postMetricsLoading: false,
    postMetricsData: data,
  };
};
const getDashboardPostMetricsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    postMetricsLoading: false,
    error: true,
    errorMessage: 'Error in loading data',
  };
};
const HANDLERS = {
  [Types.VIEW_PROJECT_TABLE_LIST_START]: viewProjectTableListStart,
  [Types.VIEW_PROJECT_TABLE_LIST_SUCCESS]: viewProjectTableListSuccess,
  [Types.VIEW_PROJECT_TABLE_LIST_ERROR]: viewProjectTableListError,
  //
  // projectsList
  [Types.PROJECTS_LIST_START]: projectsListsStart,
  [Types.PROJECTS_LIST_SUCCESS]: projectsListsSuccess,
  [Types.PROJECTS_LIST_ERROR]: projectsListsError,
  //
  [Types.PROJECT_FILTERS]: projectFiltersReducer,
  [Types.DELETE_PROJECT_START]: deleteProjectStart,
  [Types.DELETE_PROJECT_SUCCESS]: deleteProjectSuccess,
  [Types.DELETE_PROJECT_ERROR]: deleteProjectError,
  [Types.RESET_PROJECT_DATA]: resetProjectData,
  // file and folder HANDLERS
  [Types.FILE_LIST_START]: fileListStart,
  [Types.FILE_LIST_SUCCESS]: fileListSuccess,
  [Types.FILE_LIST_FAILURE]: fileListFailure,
  // create folder HANDLERS
  [Types.CREATE_FOLDER_START]: createFolderStart,
  [Types.CREATE_FOLDER_SUCCESS]: CreateFolderSuccess,
  [Types.CREATE_FOLDER_FAILURE]: CreateFolderFailure,
  // upload file
  [Types.UPLOAD_FILE_START]: uploadFileStart,
  [Types.UPLOAD_FILE_SUCCESS]: uploadFileSuccess,
  [Types.UPLOAD_FILE_FAILURE]: uploadFileFailure,
  //
  [Types.UPDATE_UPLOAD_PROGRESS]: updateUploadProgressReducer,
  //Upload Folder
  [Types.UPLOAD_FOLDER_START]: uploadFolderStart,
  [Types.UPLOAD_FOLDER_SUCCESS]: uploadFolderSuccess,
  [Types.UPLOAD_FOLDER_FAILURE]: uploadFolderFailure,
  // delete file or folder
  [Types.DELETE_FOLDER_START]: deleteFolderStart,
  [Types.DELETE_FOLDER_SUCCESS]: deleteFolderSuccess,
  [Types.DELETE_FOLDER_FAILURE]: deleteFolderFailure,
  //
  [Types.RESET_PROJECT_FILTER]: resetProjectFilter,
  //
  [Types.TOGGLE_EDIT_MODE]: toggleEditMode,
  //
  [Types.PROJECT_DETAILS_START]: projectDetailsStartReducer,
  [Types.PROJECT_DETAILS_SUCCESS]: projectDetailsSuccessReducer,
  [Types.PROJECT_DETAILS_FAILURE]: projectDetailsErrorReducer,
  //
  [Types.ADD_LINK_START]: addLinkStart,
  [Types.ADD_LINK_SUCCESS]: addLinkSuccess,
  [Types.ADD_LINK_ERROR]: addLinkError,
  //
  [Types.DELETE_LINK_START]: deleteLinkStart,
  [Types.DELETE_LINK_SUCCESS]: deleteLinkSuccess,
  [Types.DELETE_LINK_ERROR]: deleteLinkError,
  //
  [Types.EDIT_LINK_START]: editLinkStart,
  [Types.EDIT_LINK_SUCCESS]: editLinkSuccess,
  [Types.EDIT_LINK_ERROR]: editLinkError,
  //
  [Types.UPDATE_PROJECT_START]: updateProjectStart,
  [Types.UPDATE_PROJECT_SUCCESS]: updateProjectSuccess,
  [Types.UPDATE_PROJECT_FAILURE]: updateProjectFailure,
  //
  [Types.ADD_WEBPAGES_START]: addWebsiteUrlStart,
  [Types.ADD_WEBPAGES_SUCCESS]: addWebsiteUrlSuccess,
  [Types.ADD_WEBPAGES_FAILURE]: addWebsiteUrlFailure,
  //
  [Types.GET_WEB_PAGE_LIST_START]: getWebPageListStart,
  [Types.GET_WEB_PAGE_LIST_SUCCESS]: getWebPageListSuccess,
  [Types.GET_WEB_PAGE_LIST_FAILURE]: getWebPageListFailure,
  //
  [Types.DELETE_WEB_PAGE_START]: deleteWebPageStart,
  [Types.DELETE_WEB_PAGE_SUCCESS]: deleteWebPageSuccess,
  [Types.DELETE_WEB_PAGE_FAILURE]: deleteWebPageError,

  [Types.ADD_SOCIAL_HANDLE_START]: addSocialHandleStart,
  [Types.ADD_SOCIAL_HANDLE_SUCCESS]: addSocialHandleSuccess,
  [Types.ADD_SOCIAL_HANDLE_FAILURE]: addSocialHandleError,

  [Types.LIST_SOCIAL_HANDLE_START]: listSocialHandleStart,
  [Types.LIST_SOCIAL_HANDLE_SUCCESS]: listSocialHandleSuccess,
  [Types.LIST_SOCIAL_HANDLE_FAILURE]: listSocialHandleError,

  [Types.GET_DASHBOARD_MAIN_METRICS_START]: getDashboardMainMetricsStart,
  [Types.GET_DASHBOARD_MAIN_METRICS_SUCCESS]: getDashboardMainMetricsSuccess,
  [Types.GET_DASHBOARD_MAIN_METRICS_FAILURE]: getDashboardMainMetricsFailure,

  [Types.GET_DASHBOARD_POST_METRICS_START]: getDashboardPostMetricsStart,
  [Types.GET_DASHBOARD_POST_METRICS_SUCCESS]: getDashboardPostMetricsSuccess,
  [Types.GET_DASHBOARD_POST_METRICS_FAILURE]: getDashboardPostMetricsFailure,
};

export const projectsReducer = createReducer(INITIAL_STATE, HANDLERS);
