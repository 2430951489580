import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';
import {
  Input,
  NameWrapper,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { Col, Label } from '../CalendarModal/CalendarModal.Styles';
import { STRINGS } from '../../constants/labels';
import { ASSET_STATUS, HANDLE_UPLOAD_STATUS } from '../../constants/constant';
import FormatStatusLabel from '../Dropdown/FormatStatusLabel.View';
import { FormContainer, SelectDiv } from './FormComponent.Styles';
import generateStatusStyles from '../Dropdown/StatusDropdownStyle';
import { FileLabel, Link } from './AddBrandForm.Styles';
import { Fileinput } from './CreateFolder.Styles';
import { ErrorToast } from '../../services/toast/toast';
import { TOAST_POSITION } from '../../constants/BaseStyles';

const UpdateHandleDetailsForm = ({
  fontSize,
  setUploadHandleData,
  uploadHandleData,
  edit = false,
}) => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  const [selectedStatus, setSelectedStatus] = useState(null);
  //STYLE SELECT DROPDOWN
  const dynamicStyles = generateStatusStyles();

  const handleChange = (event, type) => {
    if (event.target.files.length > 1) {
      ErrorToast(
        "Can't upload more then 1 file",
        TOAST_POSITION.TOP_CENTER,
        'error1'
      );
      return;
    }
    setUploadHandleData({
      ...uploadHandleData,
      report: event.target.files[0],
    });
  };

  const handleStatusChange = (option) => {
    setSelectedStatus(option);
    setUploadHandleData({
      ...uploadHandleData,
      type: option?.value,
      typeObj: option,
    });
  };

  useEffect(() => {
    if (edit) {
      const response = HANDLE_UPLOAD_STATUS.filter(
        (data) => data?.value === uploadHandleData?.status?.value
      );
      setSelectedStatus(response[0]);
    }
  }, [edit]);

  return (
    <FormContainer>
      <Col width={'560px'}>
        <NameWrapper fontSize={'17px'}>
          {edit ? strings.UPDATE_ENTRY : strings.ADD_NEW_ENTRY}
        </NameWrapper>
        <RoleWrapper>{strings.ENTER_DETAILS}</RoleWrapper>
      </Col>
      <FileLabel
        style={{
          position: 'relative',
        }}
      >
        <Col width={'100%'}>
          <NameWrapper>
            {strings.DRAG_N_DROP_FILE} <Link>{strings.BROWSE_FILE}</Link>
          </NameWrapper>
          <RoleWrapper>{strings.FILE_UPLOAD_TEXT}</RoleWrapper>
        </Col>

        <Fileinput
          type="file"
          accept=".csv,.xlx,.xlsx"
          // multiple
          onChange={(event) => handleChange(event, 'file')}
        />
      </FileLabel>

      {uploadHandleData?.post_metrics === false ? (
        <>
          <Col justify={'flex-end'} width={'270px'}>
            <Label fontSize={fontSize}>{strings.STATUS_TYPE}</Label>
          </Col>
          <SelectDiv marginLeft={'55px'}>
            <Select
              options={HANDLE_UPLOAD_STATUS}
              value={selectedStatus}
              onChange={handleStatusChange}
              searchable={true}
              styles={dynamicStyles}
              formatOptionLabel={FormatStatusLabel}
              menuPortalTarget={document.querySelector('body')}
              placeholder={strings.SELECT_STATUS}
            />
          </SelectDiv>
        </>
      ) : null}
    </FormContainer>
  );
};

export default UpdateHandleDetailsForm;
