import React, { useEffect, useRef, useState } from 'react';
import {
  ChatBox,
  ChatBoxBody,
  ChatBoxHeader,
  ChatBoxOverlay,
  ChatBoxToggle,
  ChatInput,
  ChatLogs,
  EmptyChatBox,
  Input,
  Loader,
  Message,
  ProfileDiv,
  SendButton,
  Status,
} from './ChatBoxComponent.styles';
import { useSelector } from 'react-redux';
import UserCard from '../../components/Cards/UserCard.View';
import DynamicSVGIcon from '../../components/DynamicSVGIcon';
import Close from '../../assets/icons/Close.svg';
import Send from '../../assets/icons/send.svg';

const ChatBoxComponent = ({ closeHandler, messageSendClick }) => {
  const { popChatBoxData, popChatBoxLoading } = useSelector(
    (state) => state.popupChatBox
  );

  const [messageText, setMessageText] = useState('');

  const chatEndRef = useRef(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [popChatBoxData, popChatBoxLoading]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      messageSendClick(messageText);
      setMessageText('');
    }
  };

  return (
    <ChatBox>
      <ChatBoxHeader>
        <ProfileDiv>
          {/* <UserCard username={'AI Bot'} showOnlyProfile={true}></UserCard> */}
          <div>
            <div> AI Bot</div>
          </div>
        </ProfileDiv>

        <ChatBoxToggle
          onClick={() => {
            closeHandler();
          }}
        >
          <DynamicSVGIcon iconUrl={Close} width="1.5rem" />
        </ChatBoxToggle>
      </ChatBoxHeader>
      <ChatBoxBody>
        <ChatLogs>
          {popChatBoxData?.length === 0 ? (
            <EmptyChatBox>Ask Me Anything</EmptyChatBox>
          ) : (
            popChatBoxData?.map((message) => {
              return (
                <>
                  <Message isBot={message?.userType === 'USER' ? false : true}>
                    {message?.summary}
                  </Message>
                </>
              );
            })
          )}
          <div ref={chatEndRef} />
        </ChatLogs>
        {popChatBoxLoading && (
          <Loader>
            <span></span>
            <span></span>
            <span></span>
          </Loader>
        )}
      </ChatBoxBody>
      <ChatInput>
        <Input
          type="text"
          placeholder="Send a message..."
          value={messageText}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setMessageText(e.target.value);
          }}
        />
        <SendButton
          onClick={() => {
            messageSendClick(messageText);
            setMessageText('');
          }}
        >
          <DynamicSVGIcon iconUrl={Send} width="1rem" />
        </SendButton>
      </ChatInput>
    </ChatBox>
  );
};

export default ChatBoxComponent;
