import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const PopupChatBoxButtonContainer = styled.div`
  background-color: ${COLORS.SECONDARY_BLUE};
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 100;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
