import { InnerLoaderContainer, InnerLoaderSpinner } from './InnerLoader.Styles';

const InnerLoader = () => {
  return (
    <InnerLoaderContainer>
      <InnerLoaderSpinner />
    </InnerLoaderContainer>
  );
};

export default InnerLoader;
