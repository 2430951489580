import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const Row = styled.div`
  width: ${({ width, isReply }) => (isReply ? '95%' : width ? width : '100%')};
  height: ${({ height }) => (height ? height : 'auto')};
  min-height: ${({ height }) => (height ? height : 'auto')};
  margin-left: ${({ isReply }) => (isReply ? '15px' : '0px')};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0px')};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0px')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  display: flex;
  flex-direction: row;
  justify-content: ${({ flexStart, flexEnd, spaceBetween }) =>
    flexStart
      ? 'flex-start'
      : flexEnd
      ? 'flex-end'
      : spaceBetween
      ? 'space-between'
      : 'space-evenly'};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  background-color: ${({ bgColor, stick }) =>
    stick ? COLORS.PRIMARY_GREY : bgColor || 'inherit'};
  box-shadow: ${({ boxShadow }) =>
    boxShadow ? `0 6px 12px ${COLORS.BOX_SHADOW_COLOR}` : 'none'};
  -webkit-box-shadow: ${({ boxShadow }) =>
    boxShadow ? `0 6px 12px ${COLORS.BOX_SHADOW_COLOR}` : 'none'};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? `1px solid ${COLORS.OVERLAY_GREY}` : 'none'};
  border-left: ${({ borderLeft }) =>
    borderLeft ? `2px solid ${COLORS.PRIMARY_GREY}` : 'none'};

  font-size: ${({ fontSize }) => (fontSize ? fontSize : 'inherit')};
  position: ${({ stick }) => (stick ? 'sticky' : 'relative')};
  position: ${({ stick }) => (stick ? '-webkit-sticky' : 'relative')};
  top: 0;
  z-index: ${({ stick }) => (stick ? '2' : '0')};
  border-radius: ${({ borderBottom }) =>
    borderBottom ? 'none' : '0px 0px 12px 12px;'};
  border-radius: ${({ allBorderRadius }) =>
    allBorderRadius ? '12px' : '0px 0px 12px 12px;'};

  display: ${({ visibility }) => (visibility ? visibility : 'visible')};
  gap: ${({ gap }) => (gap ? gap : '0px')};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? 'wrap' : 'nowrap')};

  @media (max-width: 901px) {
    flex-direction: ${({ changeDirection }) =>
      changeDirection ? 'column' : 'row'};
    align-items: ${({ changeDirection }) =>
      changeDirection ? 'center' : 'inherit'};
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ bg }) => (bg ? bg : 'inherit')};
  justify-content: ${({ justify }) => (justify ? justify : 'center')};
  align-items: ${({ align }) => (align ? align : 'center')};
  width: ${({ width }) => (width ? width : '180px')};
  height: ${({ height }) => (height ? height : 'auto')};
  margin-left: ${({ isReply }) => (isReply ? '15px' : '0px')};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0px')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? `1px solid ${COLORS.OVERLAY_GREY}` : 'none'};
  border-bottom: ${({ lightBorderBottom }) =>
    lightBorderBottom ? `1px solid ${COLORS.PRIMARY_GREY}` : 'none'};
  border: ${({ lightBorder }) =>
    lightBorder ? `1px solid ${COLORS.PRIMARY_GREY}` : 'none'};
  border-radius: ${(props) => (props.lightBorder ? '12px' : 'none')};
  border-radius: ${(props) => (props.img ? '12px' : 'none')};
  &:hover {
    ${(props) => props.img && `border: 3px solid ${COLORS.PURPLE};`}
  }
  background-color: ${({ bgColor }) =>
    bgColor ? COLORS.DASHBOARD_CARD_BACKGROUND_COLOR : 'inherit'};

  @media (max-width: 901px) {
    flex-direction: ${({ maximizeResizeWidth }) =>
      maximizeResizeWidth ? '60vw' : '180px'};
    min-width: ${({ maximizeResizeWidth }) =>
      maximizeResizeWidth ? '100%' : 'inherit'};
  }
`;
export const ProjectTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ bg }) => (bg ? bg : 'inherit')};
  justify-content: ${({ justify }) => (justify ? justify : 'center')};
  align-items: ${({ align }) => (align ? align : 'center')};
  width: ${({ width }) => (width ? width : '180px')};
  height: ${({ height }) => (height ? height : 'auto')};
  margin-left: ${({ isReply }) => (isReply ? '15px' : '0px')};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0px')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? `1px solid ${COLORS.OVERLAY_GREY}` : 'none'};
  border-bottom: ${({ lightBorderBottom }) =>
    lightBorderBottom ? `1px solid ${COLORS.PRIMARY_GREY}` : 'none'};
  border-radius: ${(props) => (props.img ? '12px' : 'none')};
  border: ${({ Selected }) => Selected && `3px solid ${COLORS.PURPLE}`};
  &:hover {
    ${(props) => props.img && `border: 3px solid ${COLORS.PURPLE};`}
  }

  @media (max-width: 901px) {
    flex-direction: ${({ maximizeResizeWidth }) =>
      maximizeResizeWidth ? '60vw' : '180px'};
    min-width: ${({ maximizeResizeWidth }) =>
      maximizeResizeWidth ? '100%' : 'inherit'};
  }
`;

export const Heading = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
`;

export const Label = styled.div`
  text-align: left;
  width: ${({ width }) => (width ? width : '150px')};
  margin-top: ${({ noMarginTop }) => (noMarginTop ? '0px' : '20px')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  color: ${COLORS.TEXT_MEDIUM_GREY};
`;
export const ModalLabel = styled.div`
  width: 250px;
  padding-bottom: 13px;
`;

export const DateWrapper = styled.div`
  .react-time-picker__wrapper {
    width: 230px;
  }
`;
