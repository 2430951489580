import { PopupChatBoxButtonContainer } from './PopupChatBox.Styles';
import DynamicSVGIcon from '../../components/DynamicSVGIcon';
import Chatbot from '../../assets/icons/Chatbot.svg';
import ChatBoxComponent from './ChatBoxComponent.View';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { PopupChatBoxCreators } from './store/action';

const PopupChatBox = () => {
  const [chatBoxActive, setChatBoxActive] = useState(false);

  const handleCloseChatBot = () => {
    setChatBoxActive(false);
  };
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(
  //     PopupChatBoxCreators.chatbotQuery({
  //       text: 'Mesages',
  //     })
  //   );
  // }, []);

  const messageSendClick = (text) => {
    dispatch(
      PopupChatBoxCreators.chatbotQuery({
        text: text,
      })
    );
  };

  return (
    <>
      <PopupChatBoxButtonContainer
        onClick={() => {
          setChatBoxActive(true);
        }}
      >
        <DynamicSVGIcon width={'30px'} height={'30px'} iconUrl={Chatbot} />
      </PopupChatBoxButtonContainer>
      {chatBoxActive && (
        <ChatBoxComponent
          closeHandler={handleCloseChatBot}
          messageSendClick={messageSendClick}
        />
      )}
    </>
  );
};

export default PopupChatBox;
