import React, { useEffect, useState } from 'react';
import {
  BtnContainer,
  Header,
  SubHeaderDiv,
  TabButton,
  TabHeaderDiv,
} from './ProjectDetailBaseView.Styles';

import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { STRINGS } from '../../constants/labels';
import {
  BRAND_ERROR,
  BTN_TEXT,
  CLIENT_TYPE_USER_LIST,
  FOLDER_UPLOAD_OPTION,
  PROJECT_TAB_LIST,
} from '../../constants/constant';
import { Row } from '../../components/CalendarModal/CalendarModal.Styles';
import { Button } from '../../constants/GlobalStyles.Styles';
import UniversalModal from '../../components/modals/UniversalModal';
import AddLinkForm from '../../components/form/AddLinkForm.View';
import { Creators } from './store/action';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorToast } from '../../services/toast/toast';
import { TOAST_POSITION } from '../../constants/BaseStyles';
import { SubHeaderAction } from '../../constants/functions';
import UseClickOutside from '../../utils/useClickOutside/UseClickOutSide';
import OptionCard from '../../components/options/OptionCard';
import CreateFolder from '../../components/form/CreateFolder.View';
import UploadFiles from '../../components/form/UploadFiles.View';
import AddProjectform from '../../components/form/AddProjectForm.View';
import AddUrlForm from '../../components/form/AddUrlForm.View';
import AddHandlesLinkForm from '../../components/form/AddHandlesLinkForm.View';

export const ProjectDetailBaseView = () => {
  //TEXT
  const strings = STRINGS['ENGLISH'];
  const [searchParams] = useSearchParams();
  //navigation
  const navigate = useNavigate();

  //redux
  const dispatch = useDispatch();

  //use params
  const { project_id, project_path } = useParams();
  //
  const { userData } = useSelector((state) => state.loggedinUser);

  const { projectsFileHeader, projectDetails } = useSelector(
    (state) => state.project
  );
  //location
  const location = useLocation();
  const [progress, setProgress] = useState(0);

  const [files, setFiles] = useState([]);
  const [pageUrl, setPageUrl] = useState('');
  const [folderName, setFolderame] = useState('');
  const [isOpenModal, setIsOpenModal] = useState({
    addWebsiteModal: false,
    fileOptionDropDown: false,
    createFolder: false,
    uploadFile: false,
    editProjects: false,
    addURL: false,
    addHandels: false,
  });
  const [uploadFile, setUploadFile] = useState(false);
  const [uploadFolder, setUploadFolder] = useState(false);
  const [createFile, setCreateFile] = useState(false);
  const [formValues, setFormValues] = useState({
    project_name: '',
    project_type: '',
    website_url: '',
    base_company_spoc_username: '',
    base_company_project_lead_username: '',
    agency_users: [],
    client_spoc_username: '',
    client_users: [],
    started_at: '',
    description: '',
    // socialMediaLinks: [],
  });
  const [projectSelected, setProjectSelected] = useState('');

  const [activeTab, setActiveTab] = useState();

  const projectType = searchParams.get('project_type');
  const folderId = searchParams.get('folder_id');
  const pathname = location.pathname;

  const [addLinkData, setAddLinkData] = useState({
    title: '',
    link: '',
    status: {},
    project_id: project_id,
    // created_by: userData?.username,
  });

  const [handelsForm, setHandelsForm] = useState({
    project_id: project_id,
    handle_name: '',
    handle_url: '',
    handle_type: '',
  });

  //nav
  const navigateTo = (url) => {
    navigate(url, { replace: true });
  };
  const onclickTableAction = () => {
    if (activeTab === 'table') {
      setIsOpenModal({
        ...isOpenModal,
        addWebsiteModal: true,
      });
    } else if (activeTab === 'files') {
      setIsOpenModal({
        ...isOpenModal,
        fileOptionDropDown: true,
      });
    } else if (activeTab === 'pages') {
      setIsOpenModal({
        ...isOpenModal,
        addURL: true,
      });
    } else if (activeTab === 'handles') {
      setIsOpenModal({
        ...isOpenModal,
        addHandels: true,
      });
    } else {
      setIsOpenModal({
        ...isOpenModal,
        editProjects: true,
      });
      setFormValues({
        project_id: project_id,
        project_name: projectDetails[project_id]?.project_name,
        project_type: projectDetails[project_id]?.project_type,
        website_url: projectDetails[project_id]?.website_url,
        base_company_spoc_username:
          projectDetails[project_id]?.base_company_spoc_user?.username,
        base_company_project_lead_username:
          projectDetails[project_id]?.base_company_project_lead_username,
        agency_users: projectDetails[project_id]?.agency_users || [],
        client_spoc_username:
          projectDetails[project_id]?.client_spoc_user?.username,
        client_users: projectDetails[project_id]?.client_users || [],
        started_at: projectDetails[project_id]?.started_at,
        description: projectDetails[project_id]?.description,
      });
    }
  };
  useEffect(() => {
    SubHeaderAction(pathname, setActiveTab);
  }, [pathname]);

  // CALENDAR CLICK FUNCUTIONS
  const handleTabClick = (data) => {
    navigateTo(
      `/${project_path}/${project_id}/${data?.tab}?project_name=${projectDetails[project_id]?.project_name}`
    );
  };

  const closeModal = () => {
    setIsOpenModal({
      ...isOpenModal,
      addWebsiteModal: false,
      fileOptionDropDown: false,
      createFolder: false,
      uploadFile: false,
      editProjects: false,
      addURL: false,
      addHandels: false,
    });
    setFormValues({
      project_name: '',
      project_type: '',
      website_url: '',
      base_company_spoc_username: '',
      base_company_project_lead_username: '',
      agency_users: [],
      client_spoc_username: '',
      client_users: [],
      started_at: '',
      description: '',
      // socialMediaLinks: [],
    });
    setHandelsForm({
      project_id: project_id,
      handle_name: '',
      handle_url: '',
      handle_type: '',
    });
    setPageUrl('');
    setAddLinkData({
      title: '',
      link: '',
      status: {},
      project_id: project_id,
    });
  };

  //
  const onClickOption = (data, additionalData) => {
    const { key } = data;
    // const { company_id } = additionalData;
    switch (key) {
      case 'create':
        setCreateFile(true);
        break;
      case 'uploadFile':
        setUploadFile(true);
        break;
      case 'uploadfolder':
        setUploadFolder(true);
        break;

      default:
        break;
    }
  };
  const CreateFolders = () => {
    const payload = {
      folder_name: folderName,
      project_id: project_id,
      dir_path: '/',
    };
    if (folderId) {
      payload['base_folder_id'] = folderId;
      payload['dir_path'] = projectsFileHeader?.curr_dir_path;
    }
    if (folderName) {
      dispatch(Creators.CreateFolder({ payload }));
      setCreateFile(false);
    } else {
      ErrorToast('Enter folder Name', TOAST_POSITION.TOP_CENTER, 'error1');
    }
  };
  //
  const addWebsite = () => {
    if (userData?.username) {
      if (!addLinkData?.title) {
        ErrorToast(
          'Title can not be empty',
          TOAST_POSITION.TOP_CENTER,
          'error1'
        );
        return;
      }
      const urlRegex =
        /^(https?:\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/;
      if (!urlRegex.test(addLinkData?.link)) {
        ErrorToast('Invalid link', TOAST_POSITION.TOP_CENTER, 'error1');
        return;
      }
      dispatch(
        Creators.addLink({
          data: { ...addLinkData, created_by: userData?.username },
        })
      );
      setAddLinkData({
        ...addLinkData,
        title: '',
        link: '',
        status: {},
      });
    }
    setIsOpenModal({
      ...isOpenModal,
      addWebsiteModal: false,
    });
  };

  const addHandelsClick = () => {
    //
    setIsOpenModal({
      ...isOpenModal,
      addHandels: false,
    });

    //
    const payload = {
      ...handelsForm,
      created_by: userData?.username,
    };
    if (handelsForm?.handle_name?.length === 0) {
      ErrorToast(
        'Handle name can not be empty',
        TOAST_POSITION.TOP_CENTER,
        'error1'
      );
      return;
    }
    if (handelsForm?.handle_url?.length === 0) {
      ErrorToast(
        'Handle link can not be empty',
        TOAST_POSITION.TOP_CENTER,
        'error1'
      );
      return;
    }
    if (handelsForm?.handle_type?.length === 0) {
      ErrorToast(
        'Handle type can not be empty',
        TOAST_POSITION.TOP_CENTER,
        'error1'
      );
      return;
    }
    if (handelsForm?.handle_type?.length > 0) {
      dispatch(Creators.addSocialHandle({ data: payload }));
    }
  };

  //
  const uploadfolder = () => {
    if (!files.length) {
      ErrorToast(
        'Select folder to upload',
        TOAST_POSITION.TOP_CENTER,
        'error1'
      );
      return;
    }
    // if (files) {
    dispatch(
      Creators.uploadFolder({
        files,
        project_id,
        projectType,
        ...(folderId && { folderId }),
        progress,
        setProgress,
        // pathName,
      })
    );
    setUploadFolder(false);
    setFiles([]);
  };

  useEffect(() => {
    if (project_id) {
      dispatch(Creators.projectDetails({ projectId: project_id }));
    }
  }, [dispatch, project_id]);

  //
  const editProject = () => {
    let fromerror = false; // Initialize to false
    Object.keys(formValues).forEach((field) => {
      if (
        formValues[field]?.length === 0 &&
        field !== 'client_users' &&
        field !== 'agency_users'
      ) {
        // Check if length is 0
        ErrorToast(
          BRAND_ERROR[field],
          TOAST_POSITION.TOP_CENTER,
          `error_${field}`
        );
        fromerror = true; // Set to true if any field is empty
      }
    });

    // If no errors, dispatch action
    if (!fromerror) {
      dispatch(Creators.updateProject({ updateProjectData: formValues }));
      fromerror = false;
      closeModal();
    }
  };
  //
  const uploadfiles = () => {
    if (!files.length) {
      ErrorToast(
        'Select folder to upload',
        TOAST_POSITION.TOP_CENTER,
        'error1'
      );
      return;
    }
    const formdata = new FormData();
    files.forEach((file) => {
      formdata.append('files', file);
    });
    if (folderId) {
      formdata.append('folder_id', folderId);
    }
    formdata.append('project_id', project_id);
    dispatch(
      Creators.uploadFile({
        formData: formdata,
        project_id,
        projectType,
        ...(folderId && { folderId }),
      })
    );
    setUploadFile(false);
    setFiles([]);
  };
  const addWebsiteUrl = () => {
    if (userData?.username) {
      const urlRegex =
        /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/;
      if (urlRegex.test(pageUrl)) {
        dispatch(
          Creators.addWebpages({
            url: pageUrl,
            projectId: project_id,
            username: userData?.username,
          })
        );
        closeModal();
      } else {
        ErrorToast('Invalid website link', TOAST_POSITION.TOP_CENTER, 'error1');
      }
    }
  };
  return (
    <>
      <Header top={'140px'} left={'100px'}>
        <SubHeaderDiv style={{ marginBottom: '-2px' }}>
          <TabHeaderDiv>
            {projectDetails[project_id]?.project_type
              ? PROJECT_TAB_LIST[projectDetails[project_id]?.project_type].map(
                  (data) => (
                    <TabButton
                      active={pathname.includes(data?.tab)}
                      onClick={() => {
                        handleTabClick(data);
                      }}
                    >
                      {data.tab}
                    </TabButton>
                  )
                )
              : null}
          </TabHeaderDiv>
          {!CLIENT_TYPE_USER_LIST.includes(userData?.type) && (
            <BtnContainer>
              {BTN_TEXT[activeTab] && (
                <Button
                  width={'130px'}
                  height={'35px'}
                  noBoxShadow={true}
                  marginTop={'-8px'}
                  onClick={() => {
                    onclickTableAction();
                  }}
                >
                  {BTN_TEXT[activeTab]}
                </Button>
              )}
              {isOpenModal?.fileOptionDropDown && (
                <UseClickOutside
                  onClickOutside={() => {
                    setIsOpenModal({
                      ...isOpenModal,
                      fileOptionDropDown: false,
                    });
                  }}
                >
                  <OptionCard
                    top={'42px'}
                    right={'10px'}
                    onClickOption={onClickOption}
                    // additionalData={data}
                    option={FOLDER_UPLOAD_OPTION}
                  />
                </UseClickOutside>
              )}
            </BtnContainer>
          )}
        </SubHeaderDiv>
      </Header>
      {/* <-------------------Modals for add pages-------------------> */}
      <UniversalModal
        width={'600px'}
        height={'320px'}
        isOpenModal={isOpenModal?.addURL}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.ADD}
        onrightbtnClick={addWebsiteUrl}
        onleftbtnClick={closeModal}
      >
        <AddUrlForm pageUrl={pageUrl} setPageUrl={setPageUrl} />
      </UniversalModal>
      {/* <-------------------Modals for add website-------------------> */}
      <UniversalModal
        width={'600px'}
        height={'420px'}
        isOpenModal={isOpenModal?.addWebsiteModal}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.ADD}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={addWebsite}
        onleftbtnClick={closeModal}
      >
        {/* {formType === FILES && <AddLinkForm />} */}
        {activeTab === 'table' && (
          <AddLinkForm
            addLinkData={addLinkData}
            setAddLinkData={setAddLinkData}
          />
        )}
      </UniversalModal>
      {/* <-------------------Modals for add handels-------------------> */}
      <UniversalModal
        width={'600px'}
        height={'420px'}
        isOpenModal={isOpenModal?.addHandels}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.ADD}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={addHandelsClick}
        onleftbtnClick={closeModal}
      >
        {/* {formType === FILES && <AddLinkForm />} */}
        {activeTab === 'handles' && (
          <AddHandlesLinkForm
            handelsForm={handelsForm}
            setHandelsForm={setHandelsForm}
          />
        )}
      </UniversalModal>
      {/*<-------------------Modal for create folder-------------------->  */}
      <UniversalModal
        width={'600px'}
        height={'300px'}
        rightBtnWidth={'120px'}
        isOpenModal={createFile}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.CREATE_FOLDER}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={CreateFolders}
        onleftbtnClick={() => {
          setCreateFile(false);
        }}
      >
        <CreateFolder folderName={folderName} setFolderame={setFolderame} />
      </UniversalModal>
      {/*<-------------------Modal for upload folder-------------------->  */}
      <UniversalModal
        width={'600px'}
        height={'450px'}
        rightBtnWidth={'120px'}
        isOpenModal={uploadFolder}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.UPLOAD_FOLDER}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={uploadfolder}
        onleftbtnClick={() => {
          setFiles([]);
          setUploadFolder(false);
        }}
      >
        <UploadFiles files={files} setFiles={setFiles} />
      </UniversalModal>
      {/*<-------------------Modal for upload files-------------------->  */}
      <UniversalModal
        width={'600px'}
        height={'450px'}
        rightBtnWidth={'120px'}
        isOpenModal={uploadFile}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.UPLOAD_FILES}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={uploadfiles}
        onleftbtnClick={() => {
          setFiles([]);
          setUploadFile(false);
        }}
      >
        <UploadFiles files={files} setFiles={setFiles} file={true} />
      </UniversalModal>
      {/* <---------------------edit project---------------> */}
      <UniversalModal
        isOpenModal={isOpenModal.editProjects}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.UPDATE}
        onrightbtnClick={editProject}
        onleftbtnClick={closeModal}
      >
        <AddProjectform
          formValues={formValues}
          setFormValues={setFormValues}
          projectTypes={projectSelected}
          base_company_id={projectDetails[project_id]?.base_company_id}
          edit={true}
        />
      </UniversalModal>
      {/* <-----------------Modal for Edit project-----------------------> */}
      <Outlet context={project_id}></Outlet>
    </>
  );
};
