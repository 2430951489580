import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const ChatBox = styled.div`
  width: 400px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background: white;
  position: absolute;
  bottom: 30px;
  right: 20px;
  z-index: 100;
  height: 35rem;
`;

export const ChatBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${COLORS.SECONDARY_BLUE};
  font-family: PoppinsMedium;
  font-size: 1.4rem;
  color: ${COLORS.WHITE};
`;

export const ChatBoxToggle = styled.span`
  cursor: pointer;
`;

export const ChatBoxBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #f9f9f9;
  height: 100%;
  overflow: auto;
`;

export const ChatBoxOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: none;
`;

export const ChatLogs = styled.div`
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const ChatInput = styled.div`
  padding: 10px;
  background: ${COLORS.WHITE};
  display: flex;
  align-items: center;
  border-top: 1px solid #ddd;
`;

export const Input = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  color: ${COLORS.PRIMARY_GREY};
`;

export const SendButton = styled.button`
  background-color: ${COLORS.SECONDARY_BLUE};
  border: none;
  color: white;
  padding: 8px;
  margin-left: 5px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfileDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 18px;
`;

export const Status = styled.div`
  font-family: PoppinsRegular;
  font-size: 0.8rem;
  color: ${COLORS.WHITE};
`;

export const EmptyChatBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: PoppinsRegular;
  color: ${COLORS.TEXT_GREY};
  height: 100%;
`;

export const Message = styled.div`
  max-width: 90%;
  padding: 8px;
  margin: 5px;
  border-radius: 8px;
  word-wrap: break-word;
  font-size: 0.8rem;
  font-family: PoppinsRegular;
  background: ${({ isBot }) =>
    isBot ? `${COLORS.SECONDARY_BLUE}` : `${COLORS.ACTIVE_BG}`};
  color: ${COLORS.WHITE};
  align-self: ${({ isBot }) => (isBot ? 'flex-start' : 'flex-end')};
`;

const dotFlashing = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.3; }
  100% { opacity: 1; }
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  margin: 8px;
  align-self: flex-start;

  span {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background: ${COLORS.PRIMARY_GREY};
    border-radius: 50%;
    animation: ${dotFlashing} 1.5s infinite ease-in-out;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
`;
