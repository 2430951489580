import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';
import {
  Input,
  NameWrapper,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { Col, Label } from '../CalendarModal/CalendarModal.Styles';
import { STRINGS } from '../../constants/labels';
import { ASSET_STATUS, SOCIAL_MEDIA } from '../../constants/constant';
import FormatStatusLabel from '../Dropdown/FormatStatusLabel.View';
import { FormContainer, SelectDiv } from './FormComponent.Styles';
import generateStatusStyles from '../Dropdown/StatusDropdownStyle';

const AddHandlesLinkForm = ({
  fontSize,
  setHandelsForm,
  handelsForm,
  edit = false,
}) => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  const [selectedStatus, setSelectedStatus] = useState(null);
  //STYLE SELECT DROPDOWN
  const dynamicStyles = generateStatusStyles();

  const handleStatusChange = (option) => {
    setSelectedStatus(option);
    setHandelsForm({
      ...handelsForm,
      handle_type: option.value?.toUpperCase(),
    });
  };

  useEffect(() => {
    if (edit) {
      const response = ASSET_STATUS.filter(
        (data) => data?.value === handelsForm?.status?.value
      );
      setSelectedStatus(response[0]);
    }
  }, [edit]);

  return (
    <FormContainer>
      <Col width={'560px'}>
        <NameWrapper fontSize={'17px'}>
          {edit ? strings.UPDATE_ENTRY : strings.ADD_NEW_ENTRY}
        </NameWrapper>
        <RoleWrapper>{strings.ENTER_HANDLES_DETAILS}</RoleWrapper>
      </Col>
      <Col justify={'flex-end'} width={'270px'}>
        <Label fontSize={fontSize}>{strings.NAME}</Label>
      </Col>
      <Input
        width={'450px'}
        type="text"
        placeholder={strings.ENTER_TITLE}
        value={handelsForm?.handle_name}
        maxLength={100}
        onChange={(e) => {
          setHandelsForm({
            ...handelsForm,
            handle_name: e.target.value,
          });
        }}
      />
      <Col justify={'flex-end'} width={'270px'}>
        <Label fontSize={fontSize}>{strings.SELECT_SOCIAL_MEDIA}</Label>
      </Col>
      <SelectDiv marginLeft={'55px'}>
        <Select
          options={SOCIAL_MEDIA}
          placeholder={strings.SELECT_SOCIAL_MEDIA}
          value={selectedStatus}
          onChange={handleStatusChange}
          searchable={true}
          styles={dynamicStyles}
          formatOptionLabel={FormatStatusLabel}
          menuPortalTarget={document.querySelector('body')}
        />
      </SelectDiv>
      <Col justify={'flex-end'} width={'270px'}>
        <Label fontSize={fontSize}>{strings.LINK}</Label>
      </Col>
      <Input
        width={'450px'}
        type="text"
        placeholder={strings.ENTER_LINK}
        value={handelsForm?.handle_url}
        onChange={(e) => {
          const inputValue = e.target.value;
          let modifiedLink = inputValue;

          if (
            !inputValue.startsWith('http') &&
            !inputValue.startsWith('https')
          ) {
            // If the link doesn't start with "http" or "https", add "https://"
            modifiedLink = `https://${inputValue}`;
          }

          setHandelsForm({
            ...handelsForm,
            handle_url: modifiedLink,
          });
        }}
      />
    </FormContainer>
  );
};

export default AddHandlesLinkForm;
