/**
 * functions js
 *
 * all base functions
 *
 *
 * Project: MarkoApp
 *
 * Devlopers:
 * 1.Panorbit Services LLP
 */

import moment from 'moment';
import { cookie } from '../services/cookies/cookieServices';
import { ErrorToast } from '../services/toast/toast';
import { COLORS, TOAST_POSITION } from './BaseStyles';
import { orderBy, toNumber, replace } from 'lodash'; // Import necessary functions from lodash library

import {
  ASSET_STATUS,
  COLOR_POST_MAPPING,
  DUMMY_PROJECT_COLOR_MAPPING,
  ONLY_ALPHABETS,
  ONLY_DECIMAL_AND_DIGIT,
  ONLY_DIGITS,
  ONLY_NUMBERS,
} from './constant';
import { STRINGS } from './labels';

/**
 * Get a random integer between `min` and `max`.
 *
 * @param {number} min - min number
 * @param {number} max - max number
 * @return {number} a random integer
 */

// const GET_RANDOM_INTEGER = (min, max) => {
//   return Math.floor(Math.random() * (max - min + 1) + min);
// };

/**
 * will trigger if api call fails
 *
 * args:
 *     err: error object
 *
 * return:
 *     errorMessage inside object
 */

const ERROR_HANDLING = (err, displayToast = true) => {
  const strings = STRINGS['ENGLISH'];
  let payload;
  if (err && err.response && err.response.status) {
    switch (err.response.status) {
      case 400:
        payload = {
          errorMessage:
            strings[err?.response?.data?.response] ||
            err?.response?.data?.message ||
            strings.DEFAULT_ERR_400,
        };
        break;
      case 404:
        payload = {
          errorMessage:
            err?.response?.data?.message ||
            strings[err?.response?.data?.response] ||
            strings.DEFAULT_ERR_400,
        };
        break;
      case 500:
        payload = {
          errorMessage:
            strings[err?.response?.data?.message] || strings.DEFAULT_ERR_500,
        };
        break;
      default:
        payload = {
          errorMessage: strings.DEFAULT_ERR,
        };
    }
  } else {
    payload = {
      errorMessage: strings.INTERNET_ERR,
    };
  }
  displayToast &&
    ErrorToast(payload.errorMessage, TOAST_POSITION.TOP_CENTER, 'error1');

  return payload;
};

/**
 * will trigger if api call fails
 *
 * args:
 *     err: error object
 *
 * return:
 *     errorMessage inside object
 */

const SANITIZE_S3_URL = (url) => {
  return url.replace(/,/g, '');
};

const ALLOW_ONLY_NUMBERS = (text) => {
  if (text.trim() === '') {
    return '';
  } else {
    return parseInt(text.replace(ONLY_NUMBERS, '') || 0);
  }
};
//
const ALLOW_ONLY_NUMBERS_AND_DECIMAL = ({
  text,
  setCalibration,
  calibration,
}) => {
  if (ONLY_DECIMAL_AND_DIGIT.test(text)) {
    setCalibration({
      ...calibration,
      value: text,
    });
  } else {
    return;
  }
};
//
const ALLOW_ONLY_ALPHABETS = (text) => {
  if (text.trim() === '') {
    return '';
  } else {
    return text.replace(ONLY_ALPHABETS, '') || 0;
  }
};

const REMOVE_NUMBERS = (text) => {
  if (text.trim() === '') {
    return '';
  } else {
    return text.replace(ONLY_DIGITS, '') || '';
  }
};

const GET_INITIALS_FROM_NAME = (name = 'NA') => {
  name = name.replace(/,/g, ' ');
  return name
    .match(/(^\S\S?|\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase();
};

const CHECK_DUE_DATE_CROSSED = (date) => {
  // const currentDate = moment(new Date());
  // if (moment(date).toDate() < currentDate) {
  //   return true;
  // } else {
  //   return false;
  // }

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};

const COMPARE_USER = (user) => {
  const { email } = cookie.get('userDetails');
  if (user?.email === email) {
    return true;
  }
  return false;
};

const GET_WINDOW_SIZE = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

const RETURN_USER_NAME_COMMENT = (str, userTaggedList) => {
  if (!str) {
    return '';
  }
  let newstr = str.replaceAll(
    /\[(@[A-Za-z0-9 ]+)\]\{[0-9]+}/g,
    '<span class="taged_user">$1</span>'
  );
  newstr = newstr.replaceAll(
    /\[(@[^\s]*@[a-z0-9.-]+)\]\{[0-9]+}/g,
    '<span class="taged_user">$1</span>'
  );
  return newstr;
};

const RETURN_USER_NAME = (str, userTaggedList) => {
  if (!str) {
    return '';
  }
  let newstr = str.replaceAll(/\[(@[A-Za-z0-9 ]+)\]\{[0-9]+}/g, '$1 ');
  newstr = newstr.replaceAll(/\[(@[^\s]*@[a-z0-9.-]+)\]\{[0-9]+}/g, '$1 ');
  return newstr;
};

const DOWNLOAD_FILES_FROM_URL = (files) => {
  function download_next(i) {
    if (i >= files.length) {
      return;
    }
    var a = document.createElement('a');
    a.href = files[i].file_url;
    a.target = '_top';
    if ('download' in a) {
      a.download = files[i].file_name;
    }
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click();
    }
    a.parentNode.removeChild(a);

    setTimeout(function () {
      download_next(i + 1);
    }, 500);
  }
  download_next(0);
};

/**
 *
 * @param {Name of File} filename
 * @param {List of allowed extensions} extensions
 * @returns Bool true or false
 */
const CHECK_FILE_IN_ALLOWED_EXTENSIONS = (filename, extensions = []) => {
  const ext = filename.split('.').pop().toLowerCase() || filename.toLowerCase();
  return extensions.includes(ext);
};

/**
 *
 * @param {Name of File} filename
 * @returns string extension
 * based on the Extension exists in PREVENT_2D_CAD_VIEW list
 */
const GET_EXTENSION_FROM_FILE_NAME = (filename) => {
  if (!filename) {
    return '';
  }
  const ext =
    filename?.split('.').pop().toUpperCase() || filename.toUpperCase();
  return ext;
};

const FORMAT_SEEK_TIME = (video) => {
  let minutes = Math.floor(video.currentTime / 60, 10);
  let seconds = Math.floor(video.currentTime % 60)
    .toFixed(0)
    .padStart(2, '0');
  return `${minutes}:${seconds}`;
};

const CONVERT_TIMESTAMP_TO_SECONDS = (timestamp) => {
  const [minutes, seconds] = timestamp.split(':');
  const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds);

  return totalSeconds;
};

const UPDATE_DATES = (obj) => {
  if (obj && obj.started_at) {
    obj.started_at = moment(obj.started_at).toDate();
  }

  if (obj && obj.ended_at) {
    obj.ended_at = moment().toDate();
  }

  return obj;
};

const FORMAT_TIMESTAMP = (timestamp, format = 'D-MM-YYYY hh:mm A') => {
  return moment(timestamp).format(format);
};

const CHECK_TIME_DIFFERENCE = (date) => {
  const activityDate = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
  const userCurrentDate = moment();
  const diffInMilliseconds = userCurrentDate.diff(activityDate);

  if (diffInMilliseconds < 10000000) {
    // Display relative time for differences less than 10000000 milliseconds
    return moment(date).fromNow();
  }
  // Display the date in the specified format for larger differences
  return activityDate.format('Do MMM YYYY hh:mm A');
};
// const date = moment("2024-04-25T06:09:30.199603Z");
// const formattedDate = date.format('Do MMMM YYYY');
/**
 * Get color based on status from COLOR_POST_MAPPING.
 *
 * @param {string} status - status value
 * @return {string} color code
 */
const GET_STATUS_COLOR = (status) =>
  COLOR_POST_MAPPING[status] || COLORS.DEFAULT_COLOR;

/**
 * Get label corresponding to the status value from PROJECT_STATUS.
 *
 * @param {string} status - status value
 * @return {string} status label
 */
const GET_STATUS_LABEL = (status) =>
  ASSET_STATUS.find((statusItem) => statusItem.value === status)?.label;

const VERSION_SORTED = (assetVersionsListData) => {
  return orderBy(
    assetVersionsListData,
    [(asset) => toNumber(replace(asset.version_name, /[^0-9]/g, ''))],
    ['desc']
  );
};

const VERSION_TIME_SORTED = (data) => {
  const versionSorted = VERSION_SORTED(data);
  return orderBy(versionSorted, [(asset) => asset.created_at], ['desc']);
};

const CAPTION_FILTER_EMPTY = (data) => {
  return data.filter(
    (item) => item.caption.trim() !== '' && !/^\s*$/.test(item.caption)
  );
};

//get entire DOM CSS path of clicked div
const GET_CSS_PATH = (element) => {
  const path = [];
  while (element && element.nodeType === Node.ELEMENT_NODE) {
    let selector = element.nodeName.toLowerCase();
    if (element.id) {
      selector = `#${element.id}`;
    } else {
      let index = 1;
      let sibling = element.previousElementSibling;
      while (sibling) {
        if (sibling.nodeName === element.nodeName) {
          index++;
        }
        sibling = sibling.previousElementSibling;
      }
      if (index > 1) {
        selector += `:nth-child(${index})`;
      }
    }
    path.unshift(selector);
    element = element.parentElement;
  }
  return path.join(' > ');
};

//extract verion_id and version_name
const VERSION_SORTED_MAPPING = (data) =>
  data?.map((version) => ({
    value: version?.version_id,
    label: version?.version_name,
  }));

const GET_DUMMY_PROJECT_COLOR = (data) =>
  DUMMY_PROJECT_COLOR_MAPPING[data?.projectType || data?.project_type];

const STANDARDIZE_PROJECT_TYPE = (data) => {
  let projectType = data?.project_type || data?.projectType || data?.type;
  let res = projectType?.toLowerCase()?.replace(/_/g, ' ');

  return res;
};

// determines margintop & visibility of row & button based on url
export const getHeaderStyles = (pathname) => {
  let marginTop = '0';
  let buttonMarginTop = '0';
  let display = 'visible';
  let buttonDisplay = 'visible';
  let colHeight = '0';

  if (
    pathname.includes('/') ||
    pathname.includes('/home') ||
    pathname.includes('/brands') ||
    pathname.includes('/projects')
  ) {
    marginTop = '260px';
    buttonMarginTop = '260px';
  }

  if (
    pathname.includes('/projects/project_list') ||
    pathname.includes('/files') ||
    pathname.includes('/brands')
  ) {
    marginTop = '260px';
    buttonDisplay = 'none';
  }

  if (pathname.includes('/projects/project/') && pathname.includes('/event/')) {
    marginTop = '200px';
  }

  if (pathname.includes('asset-versions')) {
    marginTop = '30px';
    buttonMarginTop = '40px';
    display = 'none';
  }

  if (
    pathname.includes('/projects/project/') &&
    pathname.includes('/view-event/')
  ) {
    display = 'none';
    colHeight = '75px';
  }

  return { marginTop, buttonMarginTop, display, buttonDisplay, colHeight };
};
const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};
const SubHeaderAction = (pathname, setActiveTab) => {
  if (pathname.includes('/details')) {
    return setActiveTab('details');
  } else if (pathname.includes('/table')) {
    return setActiveTab('table');
  } else if (pathname.includes('/handles')) {
    return setActiveTab('handles');
  } else if (pathname.includes('/files')) {
    return setActiveTab('files');
  } else if (pathname.includes('/calendar')) {
    return setActiveTab('calendar');
  } else if (pathname.includes('/pages')) {
    return setActiveTab('pages');
  } else if (pathname.includes('/team_members')) {
    return setActiveTab('team_members');
  } else if (pathname.includes('/brand_members')) {
    return setActiveTab('brand_members');
  } else {
    return setActiveTab('dashboard');
  }
};

const FIND_COLOR_RANGE = (userName, whiteBG = false) => {
  if (whiteBG) {
    return `${COLORS.PRIMARY_BACKGROUND_COLOR}`;
  } else if (userName) {
    if (
      userName.toLowerCase().charAt(0) >= 'a' &&
      userName.toLowerCase().charAt(0) <= 'd'
    ) {
      return `${COLORS.PRIMARY_COLOR}`;
    } else if (
      userName.toLowerCase().charAt(0) >= 'e' &&
      userName.toLowerCase().charAt(0) <= 'l'
    ) {
      return `${COLORS.BACKGROUND_OPTION_1}`;
    } else if (
      userName.toLowerCase().charAt(0) >= 'm' &&
      userName.toLowerCase().charAt(0) <= 'n'
    ) {
      return `${COLORS.BACKGROUND_OPTION_2}`;
    } else if (
      userName.toLowerCase().charAt(0) >= 'o' &&
      userName.toLowerCase().charAt(0) <= 'r'
    ) {
      return `${COLORS.BACKGROUND_OPTION_3}`;
    } else if (
      userName.toLowerCase().charAt(0) >= 's' &&
      userName.toLowerCase().charAt(0) <= 'u'
    ) {
      return `${COLORS.BACKGROUND_OPTION_4}`;
    } else if (
      userName.toLowerCase().charAt(0) >= 'v' &&
      userName.toLowerCase().charAt(0) <= 'z'
    ) {
      return `${COLORS.BACKGROUND_OPTION_5}`;
    } else {
      return `${COLORS.BACKGROUND_OPTION_6}`;
    }
  }
  return `${COLORS.PRIMARY_COLOR}`;
};
//
export const downloadPdf = (filename, url) => {
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${filename}.pdf`; // Set the name of the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error('Error fetching the PDF:', error);
    });
};
export const downloadImage = async (imageName, imgUrl) => {
  try {
    const response = await fetch(imgUrl);
    const blob = await response.blob();

    // Create a temporary link element
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.target = '_blank'; // Open in a new tab

    // Set the download attribute if supported
    if ('download' in a) {
      a.download = `${imageName}.png`;
    } else {
      // Fallback: Open the image in a new tab
      a.removeAttribute('download');
    }

    // Add the link to the document
    (document.body || document.documentElement).appendChild(a);

    // Trigger the click event
    a.click();

    // Clean up: remove the temporary link
    a.parentNode.removeChild(a);
  } catch (error) {
    console.error('Error downloading image:', error);
  }
};

export const downloadVideo = async (filename, videoUrl) => {
  try {
    const response = await fetch(videoUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${filename}.mp4`; // You can change the file name here
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the video:', error);
  }
};
export const isElementFullScreen = (elementId) => {
  const element = document.getElementById(elementId);
  const fullscreenElement =
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement;
  return fullscreenElement === element;
};
export const GENRATE_URL_FOR_NOTIFICATION = (data) => {
  const { type, notification_meta, project_id } = data;
  let url;
  switch (type) {
    case 'COMMENT':
      url = `/projects/${project_id}/asset_review?asset_id=${notification_meta?.asset_id}&version_id=${notification_meta?.version_id}`;
      return url;
    // break;
    case 'ASSET':
      url = `/projects/${project_id}/asset_review?asset_id=${notification_meta?.asset_id}&version_id=${notification_meta?.version_id}`;
      return url;
    case 'WEBPAGE':
      url = `/projects/${project_id}/website_review?webpage_id=${notification_meta?.webpage_id}&version_id=${notification_meta?.webpage_version_id}`;
      return url;
    case 'EVENT':
      url = `/projects/${project_id}/calendar/events/${data?.ref_id}`;
      return url;
    case 'LINK':
      url = `/projects/${project_id}/table`;
      return url;
    case 'USER':
      url = `/projects/${project_id}/details`;
      return url;

    default:
      break;
  }
};

const FORMAT_DASHBOARD_MAIN_METRICS = (data) => {
  const formattedData = {
    reach: {},
    follows: {},
    interactions: {},
    link_clicks: {},
    views: {},
    visits: {},
    keys: [],
  };
  const finalData = {
    reach: [],
    follows: [],
    interactions: [],
    link_clicks: [],
    views: [],
    visits: [],
    keys: [],
  };
  try {
    data?.forEach((item) => {
      // Initialize the date object if it doesn't exist
      if (!formattedData.reach[item?.date]) {
        formattedData.reach[item?.date] = {};
        formattedData.follows[item?.date] = {};
        formattedData.interactions[item?.date] = {};
        formattedData.link_clicks[item?.date] = {};
        formattedData.views[item?.date] = {};
        formattedData.visits[item?.date] = {};
      }
      if (!formattedData.keys.includes(item?.handle_fkey__handle_name)) {
        formattedData.keys.push(item?.handle_fkey__handle_name);
      }
      formattedData.reach[item?.date][item?.handle_fkey__handle_name] =
        item?.reach;
      formattedData.follows[item?.date][item?.handle_fkey__handle_name] =
        item?.follows;
      formattedData.interactions[item?.date][item?.handle_fkey__handle_name] =
        item?.interaction;
      formattedData.link_clicks[item?.date][item?.handle_fkey__handle_name] =
        item?.link_clicks;
      formattedData.views[item?.date][item?.handle_fkey__handle_name] =
        item?.views;
      formattedData.visits[item?.date][item?.handle_fkey__handle_name] =
        item?.visits;
    });
    finalData.keys = formattedData.keys;
    Object.entries(formattedData.reach).forEach(([key, value]) => {
      finalData.reach.push({
        name: key,
        ...value,
      });
    });
    Object.entries(formattedData.follows).forEach(([key, value]) => {
      finalData.follows.push({
        name: key,
        ...value,
      });
    });
    Object.entries(formattedData.interactions).forEach(([key, value]) => {
      finalData.interactions.push({
        name: key,
        ...value,
      });
    });
    Object.entries(formattedData.link_clicks).forEach(([key, value]) => {
      finalData.link_clicks.push({
        name: key,
        ...value,
      });
    });
    Object.entries(formattedData.views).forEach(([key, value]) => {
      finalData.views.push({
        name: key,
        ...value,
      });
    });
    Object.entries(formattedData.visits).forEach(([key, value]) => {
      finalData.visits.push({
        name: key,
        ...value,
      });
    });
  } catch (error) {}
  return finalData;
};

export {
  ERROR_HANDLING,
  SANITIZE_S3_URL,
  ALLOW_ONLY_NUMBERS,
  REMOVE_NUMBERS,
  ALLOW_ONLY_ALPHABETS,
  GET_INITIALS_FROM_NAME,
  CHECK_DUE_DATE_CROSSED,
  COMPARE_USER,
  ALLOW_ONLY_NUMBERS_AND_DECIMAL,
  GET_WINDOW_SIZE,
  RETURN_USER_NAME_COMMENT,
  DOWNLOAD_FILES_FROM_URL,
  CHECK_FILE_IN_ALLOWED_EXTENSIONS,
  GET_EXTENSION_FROM_FILE_NAME,
  RETURN_USER_NAME,
  UPDATE_DATES,
  GET_STATUS_COLOR,
  GET_STATUS_LABEL,
  FORMAT_TIMESTAMP,
  CHECK_TIME_DIFFERENCE,
  FORMAT_SEEK_TIME,
  CONVERT_TIMESTAMP_TO_SECONDS,
  VERSION_SORTED,
  VERSION_TIME_SORTED,
  CAPTION_FILTER_EMPTY,
  GET_CSS_PATH,
  VERSION_SORTED_MAPPING,
  GET_DUMMY_PROJECT_COLOR,
  STANDARDIZE_PROJECT_TYPE,
  capitalizeFirstLetter,
  SubHeaderAction,
  FIND_COLOR_RANGE,
  FORMAT_DASHBOARD_MAIN_METRICS,
};
