import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

const InnerLoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InnerLoaderSpinner = styled.div`
  border: 16px solid ${COLORS.SECONDARY_BLUE};
  border-radius: 50%;
  border-top: 16px solid ${COLORS.ACTIVE_BG};
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { InnerLoaderContainer, InnerLoaderSpinner };
